import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

const TemplateModal = ({
  showModal,
  handleCloseModal,
  modalMode,
  currentTemplate,
  handleInputChange,
  handleDescriptionChange,
  handleSaveTemplate,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      dialogClassName="modal-lg"
      className="template-modal-main"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {modalMode === "create"
            ? "Create Custom Template"
            : "Update Custom Template"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="template-modal-main-modal-body">
        <Form>
          <Form.Group>
            <Form.Label>Template Name</Form.Label>
            <Form.Control
              type="text"
              name="template_name"
              value={currentTemplate.template_name}
              onChange={handleInputChange}
              placeholder="Enter template name"
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email Title</Form.Label>
            <Form.Control
              type="text"
              name="email_title"
              value={currentTemplate.email_title}
              onChange={handleInputChange}
              placeholder="Enter title"
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email Description</Form.Label>
            {/* Using react-quill editor for email description */}
            <ReactQuill
              value={currentTemplate.email_description}
              onChange={handleDescriptionChange}
              placeholder="Enter description with formatting"
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }, { font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link"],
                  ["clean"],
                ],
              }}
              formats={[
                "header",
                "font",
                // "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "list",
                "bullet",
                "link",
              ]}
              className="template-modal-ql-editor"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <p className="text-muted">
        Use placeholders like{" "}
        <span className="placeholder">{"{{company_name}}"}</span>,
        <span className="placeholder">{"{{job_role}}"}</span>, and{" "}
        <span className="placeholder">{"{{hiring_person_name}}"}</span>.
      </p>
      <Modal.Footer>
      
        <button className="main-referral-select-all-start-automation" onClick={handleSaveTemplate}>
          {modalMode === "create" ? "Create" : "Update"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default TemplateModal;
