/*global chrome*/
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useStateStore } from "../../store";
import "./AutomationPopup.css";
import ProgressBar from "./ProgressBar";
import { mixpanel } from "../../index";

function ReferralAutomationBlock({
  mainAutomationStep = "Starting Automation",
  show = false,
  setShowInfoModal,
  setInfoModalType,
  checkIfStopped,
  pauseGlobalReferralStopPlayButton,
}) {
  const globalStopReferral = useStateStore((state) => state.globalStopReferral);
  const setGlobalStopReferral = useStateStore(
    (state) => state.setGlobalStopReferral
  );
  const userData = useStateStore((state) => state.userData);
  const yourExtensionId = useStateStore((state) => state.yourExtensionId);
  const setEmailAutomationReferral = useStateStore(
    (state) => state.setEmailAutomationReferral
  );
  const setEditEmailSettings = useStateStore(
    (state) => state.setEditEmailSettings
  );
  const editEmailSettings = useStateStore((state) => state.editEmailSettings);
  const setScriptInjectedGlobal = useStateStore(
    (state) => state.setScriptInjectedGlobal
  );
  const setShowEmailSettings = useStateStore(
    (state) => state.setShowEmailSettings
  );
  const showEmailSettings = useStateStore((state) => state.showEmailSettings);
  const scriptInjectedGlobal = useStateStore(
    (state) => state.scriptInjectedGlobal
  );
  const setExtensionPresent = useStateStore(
    (state) => state.setExtensionPresent
  );
  const mainReferralPause = useStateStore((state) => state.mainReferralPause);
  const setStopClick = useStateStore((state) => state.setStopClick);
  const [time, setTime] = useState(5);
  const [time1, setTime1] = useState(5);
  const editEmail = useStateStore((state) => state.editEmail);
  const setEditEmail = useStateStore((state) => state.setEditEmail);
  const setPromptSelected = useStateStore((state) => state.setPromptSelected);
  const setInstallExtensionDuringAutomation = useStateStore(
    (state) => state.setInstallExtensionDuringAutomation
  );
  const setSaveButtonClickAndSuccess = useStateStore(
    (state) => state.setSaveButtonClickAndSuccess
  );
  const [clickOnButton, setClickOnButton] = useState(false);

  const changeTimeFn = () => {
    const intervalId = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime !== 0 && !useStateStore.getState().editEmail) {
          return prevTime - 1;
        } else {
          clearInterval(intervalId);
          return prevTime;
        }
      });
    }, 1000);
  };

  const changeTimeFn1 = () => {
    const intervalId = setInterval(() => {
      setTime1((prevTime) => {
        if (prevTime !== 0 && !useStateStore.getState().editEmailSettings) {
          return prevTime - 1;
        } else {
          clearInterval(intervalId);
          return prevTime;
        }
      });
    }, 1000);
  };

  let chromeExtensionPresent = async () => {
    let extensionPresentMain = 0;
    if (typeof chrome !== "undefined" && chrome && chrome.runtime) {
      extensionPresentMain = await new Promise((resolve, reject) => {
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            lazyapply: "lazyapply",
            message: "check",
            injected: scriptInjectedGlobal,
          },
          function (response) {
            var lastError = chrome.runtime.lastError;
            if (lastError) {
              console.log("error", lastError.message);
              resolve(0);
            }
            if ("status" in response && response.status == "installed") {
              setScriptInjectedGlobal(true);
              setExtensionPresent(true);
              resolve(1);
            }
          }
        );
      });
      return extensionPresentMain;
    } else {
      extensionPresentMain = 0;
      return extensionPresentMain;
    }
  };

  useEffect(() => {
    if (
      mainAutomationStep !== "" &&
      mainAutomationStep === "Waiting For Email Editing"
    ) {
      setTime(5);
      changeTimeFn();
    }

    if (
      mainAutomationStep !== "" &&
      mainAutomationStep === "Update Email Settings If Required."
    ) {
      setTime1(5);
      changeTimeFn1();
    }

    return () => {
      clearInterval(changeTimeFn);
      clearInterval(changeTimeFn1);
    };
  }, [mainAutomationStep]);

  useEffect(() => {
    console.log("editEmail", editEmail);
  }, [editEmail]);

  return (
    <>
      {show && (
        <>
          <div className="overlay_automation"></div>
          <div className="main-referral-block-automation-popup">
            <div>
              <img src={"/assests/panda1.png"} className="navbarImg"></img>
            </div>
            <button
              className="automation-popup-stop"
              onClick={async () => {
                setStopClick(true);
                setShowInfoModal(true);
                setInfoModalType("stop");
                setTimeout(() => {
                  setShowInfoModal(false);
                }, 2500);
                setEmailAutomationReferral(false);
                setPromptSelected({
                  form: {
                    id: -1,
                  },
                  prompt: {
                    id: -1,
                  },
                });
                await checkIfStopped();
              }}
            >
              Stop
            </button>
            <p className="automation-popup-main-text">
              Email Automation Is In Process
            </p>
            {pauseGlobalReferralStopPlayButton && (
              <button
                className="main-referral-global-pause-play"
                onClick={() => {
                  setGlobalStopReferral(!globalStopReferral);
                }}
              >
                {globalStopReferral ? <span>Play</span> : <span>Pause</span>}
              </button>
            )}

            <ProgressBar />
            {mainReferralPause.status ? (
              <>
                <div className="automation-popup-edit-email">
                  <p>{mainReferralPause.text}</p>
                  <p className="automation-popup-edit-email-text2">
                    {mainReferralPause?.text1 || ""}
                  </p>
                  {mainReferralPause.text ==
                  "Install Lazyapply Extension To Continue Automation" ? (
                    <button
                      onClick={async () => {
                        setClickOnButton(true);
                        const extensionPresent = await chromeExtensionPresent();
                        console.log("extensionPresent", extensionPresent);
                        setClickOnButton(false);
                        if (extensionPresent) {
                          setInstallExtensionDuringAutomation(true);
                        }
                      }}
                      className={"automation-popup-edit-play"}
                    >
                      Continue Automation{" "}
                      {clickOnButton && (
                        <Spinner
                          animation="border"
                          size="sm"
                          style={{ marginLeft: "5px" }}
                        />
                      )}
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <>
                {(mainAutomationStep != "" ||
                  editEmail ||
                  editEmailSettings) && (
                  <>
                    <div className="automation-popup-step-text">
                      {mainAutomationStep != ""
                        ? mainAutomationStep + "..."
                        : ""}
                      <div className="automation-popup-step-image">
                        {mainAutomationStep != "" && (
                          <img
                            src={"/assests/success_tick.webp"}
                            className="automation-popup-step-tick"
                          ></img>
                        )}
                      </div>
                    </div>

                    {mainAutomationStep == "Waiting For Email Editing" ||
                    editEmail ? (
                      <div className="automation-popup-edit-email">
                        {!editEmail && (
                          <p>Pause Automation To Edit Email In {time}</p>
                        )}
                        <button
                          onClick={() => {
                            setEditEmail(!editEmail);
                          }}
                          className={
                            editEmail
                              ? "automation-popup-edit-play"
                              : "automation-popup-edit-pause"
                          }
                        >
                          {editEmail
                            ? "Continue Automation"
                            : "Pause To Edit Email"}{" "}
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}

                    {mainAutomationStep ==
                      "Update Email Settings If Required." ||
                    editEmailSettings ? (
                      <>
                        <div className="automation-popup-edit-email">
                          {!editEmailSettings && (
                            <>
                              <p>Edit Email Settings In {time1}</p>
                            </>
                          )}
                          {editEmailSettings ? (
                            <>
                              {" "}
                              <p>Click "Save Your Choice" button</p>
                            </>
                          ) : (
                            <></>
                          )}

                          {!editEmailSettings && (
                            <button
                              onClick={() => {
                                setSaveButtonClickAndSuccess(false);
                                setShowEmailSettings(!showEmailSettings);
                                setEditEmailSettings(!editEmailSettings);
                              }}
                              className={"automation-popup-edit-pause"}
                            >
                              Edit Email Settings
                            </button>
                          )}
                          {/* {!editEmailSettings && (
                            <button
                              onClick={() => {
                                setSaveButtonClickAndSuccess(false);
                                setShowEmailSettings(!showEmailSettings);
                                setEditEmailSettings(!editEmailSettings);
                              }}
                              className={
                                editEmailSettings
                                  ? "automation-popup-edit-play"
                                  : "automation-popup-edit-pause"
                              }
                            >
                              {editEmailSettings
                                ? "Continue Automation"
                                : "Edit Email Settings"}{" "}
                            </button>
                          )} */}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default ReferralAutomationBlock;
