import "./login.css";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useStateStore } from "../store/index";
import CustomAlert from "./modal";
import { mixpanel } from "../index";
import { Form, Row, Col } from "react-bootstrap";
import { GoogleLogin } from "@react-oauth/google";
import { useGoogleOneTapLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";

const LoginComponent = ({ loginMain }) => {
  const setShowDemo = useStateStore((state) => state.setShowDemo);
  const searchParams = new URLSearchParams(window.location.search);
  const token_main = searchParams.get("token");
  const from_job_page = searchParams.get("from_job_page");
  const loading = useRef(null);
  const isUserAuthenticated = useStateStore(
    (state) => state.isUserAuthenticated
  );
  const userDataInit = useStateStore((state) => state.userDataInit);
  const userAuthentication = useStateStore((state) => state.userAuthentication);
  const updateToken = useStateStore((state) => state.updateToken);
  const userImageSrc = useStateStore((state) => state.userImageSrc);
  const history = useHistory();
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);

  const handleLoginSuccessMixpanelEvent = (typeOfButton, eventData = {}) => {};

  const handleLoginButtonPressMixpanelEvent = (
    typeOfButton,
    eventData = {}
  ) => {};

  function handleLogin(googleData, typeOfButton) {
    console.log("googledata", googleData);
    if (googleData) {
      handleLoginButtonPressMixpanelEvent(typeOfButton, {});
      axios
        .post(
          "https://backend.lazyapply.com/referral-user-service/createUser",
          {
            token: googleData,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          console.log("response", response);
          userAuthentication(1);
          userImageSrc(response.data.user.picture);
          updateToken(response.data.token);
          window.localStorage.setItem("token", response.data.token);
          window.localStorage.setItem(
            "user",
            JSON.stringify(response.data.user)
          );
          userDataInit(response.data.user);
        })
        .catch(function (error) {
          console.log("err", error);
          loading.current.style.display = "none";
          setmessage("Some error occured, please try again in some time!");
          settitle("Error");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2500);
        });
    }
  }

  useEffect(() => {
    if (token_main && token_main != "") {
      handleLogin(token_main, "refer_from_website");
      // const searchParams = new URLSearchParams(window.location.search);
      // searchParams.delete("t");
    }
    if (from_job_page && from_job_page != "") {
      setShowDemo(true);
      window.localStorage.setItem("fromjob", true);
    }
    const fromjob = window.localStorage.getItem("fromjob");
    if (fromjob != undefined && fromjob) {
      setShowDemo(true);
    }

    if (loading) loading.current.style.display = "none";
    // const fromwheretext = window.localStorage.getItem("fromwheretext");
    // if (fromwheretext != undefined && fromwheretext) {
    setshowans(false);
    setCurrentStep(2);
    //   console.log("okok");
    // } else {
    //   setCurrentStep(0);
    //   setshowans(true);
    // }
  }, [token_main, from_job_page]);

  const [showans, setshowans] = useState(false);
  const [fromwhere, setfromwhere] = useState("");
  const [value, setvalue] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      (fromwhere != "" && fromwhere != 7) ||
      (fromwhere == 7 && value != "")
    ) {
      window.localStorage.setItem("fromwheretext", true);
      setCurrentStep(2);
      setshowans(false);
      //   alert("submit call", fromwhere, value);
    }
  };

  const options = [
    { text: "Click here to select", value: "" },
    { text: "Quora", value: "Quora" },
    { text: "Reddit", value: "Reddit" },
    { text: "Youtube", value: "Youtube" },
    { text: "Blog", value: "Blog" },
    { text: "Tiktok", value: "Tiktok" },
    { text: "Online article", value: "Online article" },
    {
      text: "Recommended by friend or family",
      value: "Recommended by friend or family",
    },
    { text: "Google", value: "Google" },
    { text: "Other", value: 7 },
  ];

  const fromWhereComponent = () => {
    return (
      <>
        <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Row>
            <Col md={12}>
              <Form.Group className="form12">
                <Form.Label>Where did you get to know about us ?</Form.Label>
                <Form.Control
                  required
                  as="select"
                  id="experience"
                  onChange={(e) => {
                    setfromwhere(e.target.value);
                  }}
                >
                  {options.map((data) => {
                    return <option value={data.value}>{data.text}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            {fromwhere == 7 && (
              <Col md={12}>
                <Form.Group className="form12">
                  <Form.Label>Enter your text here ....</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="type here..."
                    id="fromwhereother"
                    onChange={(e) => {
                      setvalue(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>
          <button type="submit" className="fromwherecolor">
            Next Step
          </button>
        </Form>
      </>
    );
  };
  const [currentStep, setCurrentStep] = useState(2);

  useGoogleOneTapLogin({
    disabled: isUserAuthenticated == 1,
    onSuccess: (credentialResponse) => {
      console.log(credentialResponse);
      handleLogin(credentialResponse.credential, "one_tap");
    },
    onError: () => {
      console.log("Login Failed");
    },
  });
  return (
    <>
      <div className="loading" ref={loading}>
        <div className="boxLoading"></div>
      </div>
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      {isUserAuthenticated == 0 && (
        <div className="loginGrid">
          <div className="loginGridChild">
            <div className="loginGridChildContent logoImg">
              {loginMain ? (
                <div className="loginMainPopup">
                  <div className="loginMainPopupTitle">
                    Create Your Account Now to Start Automating Job Applications
                  </div>
                </div>
              ) : (
                <img src={"/assests/panda1.png"} alt="logo-lazyapply" />
              )}
            </div>

            {showans ? (
              <> {fromWhereComponent()}</>
            ) : (
              <>
                <div className="loginGridChildContent googleLoginButton">
                  <GoogleLogin
                    size={"large"}
                    width={"250px"}
                    text={"signup_with"}
                    theme={"filled_blue"}
                    onSuccess={(credentialResponse) => {
                      console.log("credential", credentialResponse);
                      handleLogin(credentialResponse.credential, "main_button");
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const Login = ({ loginMain = false }) => {
  const CLIENT_ID = encodeURIComponent(
    "715818145189-7geg3j3td9u21nc9qe8eujb2l4dcbt88.apps.googleusercontent.com"
  );
  return (
    <>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <LoginComponent loginMain={loginMain} />
      </GoogleOAuthProvider>
    </>
  );
};

export default Login;
