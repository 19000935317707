import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
function CustomAlert({
  message,
  title,
  s,
  link = "",
  customFn = () => {},
  lazyapplyxlink = "",
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    customFn();
  };

  useEffect(() => {
    console.log("show", s);
    setShow(s);
  }, [s]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="md"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{message}</div>
        {lazyapplyxlink != "" && (
          <div>
            <a href={lazyapplyxlink} target="_blank">
              Click here to fill the form
            </a>
          </div>
        )}
        {link != "" && (
          <div>
            <a href={link} target="_blank">
              Click here to open install extension page
            </a>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default CustomAlert;
