import React from "react";
import { useHistory } from "react-router-dom";
import "./error.css"
function Error() {
  const history = useHistory();
  function goToHome() {
    history.push("/");
  }
  return (
    <div className="page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="col-sm-10 col-sm-offset-1  text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center ">404</h1>
              </div>

              <div className="contant_box_404">
                <h3 className="h2">Look like you're lost</h3>

                <p>the page you are looking for not avaible!</p>

                <a href="" className="link_404" onClick={goToHome}>
                  Go to Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Error;
