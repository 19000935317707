import axios from "axios";
import React, { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useStateStore } from "../../store";
import "./EmailBlock.css";
import PeopleBlock from "./PeopleBlock";
import "react-quill/dist/quill.snow.css";
import EmailComposer from "./EmailComposer.js";

function EmailBlock({ setSendEmail }) {
  const [regenerateLoading, setRegenerateLoading] = useState(false);
  const [activeEmailLoading, setActiveEmailLoading] = useState(false);
  const [emailNotFound, setEmailNotFound] = useState(false);
  const userData = useStateStore((state) => state.userData);
  const [showImcompleteDataError, setShowIncompleteDataError] = useState(false);
  const [emailTitle, setEmailTitle] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [customTemplateId, setCustomTemplateId] = useState("");
  const [emailTemplateOption, setEmailTemplateOption] = useState(-1);

  const {
    setProfileSelectedDuringAutomation,
    customTemplates,
    emailList,
    setShowEmailSentSuccess,
    emailAutomationReferral,
    setShowMainReferralModal,
    referralResumeData,
    setReferralInitData,
    referralInitData,
    employeeSelected,
    mainCompanySet,
    initialString,
    setInitialString,
    setEmailDataString,
    emailData,
    setEmailData,
    setSaveButtonClickAndSuccess,
    token,
    editEmailSettings,
    setEditEmailSettings,
    setShowEmailSettings,
    showEmailSettings,
  } = useStateStore();

  const activeAiEmails = async () => {
    setActiveEmailLoading(true);
    const prompts = useStateStore.getState().referralPrompts;
    const selected_goal_id = useStateStore.getState().currentGoalId;
    const promptData = prompts.filter(
      (item) => item.goal_id === selected_goal_id
    )[0];
    await axios.post(
      `https://backend.lazyapply.com/referral/updateReferralData`,
      {
        emailTemplateOption: emailTemplateOption,
        customTemplateId: customTemplateId,
        referralTitle: promptData.referral_job_title,
        v2Id: userData?.v2Id || "",
        resumeId: useStateStore.getState().resumeReferralData.resumeId,
        permissionGranted: 1,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setSaveButtonClickAndSuccess(true);
    setEditEmailSettings(false);
    setShowEmailSettings(false);
    const referralData = referralInitData?.referral;
    setReferralInitData({
      ...referralInitData,
      referral: {
        ...referralData,
        emailTemplateOption: emailTemplateOption,
        customTemplateId: customTemplateId,
        resumeId: useStateStore.getState().resumeReferralData.resumeId,
        referralTitle: promptData.referral_job_title,
      },
    });
    console.log("save button press");
    setShowIncompleteDataError(false);
    setActiveEmailLoading(false);
    setProfileSelectedDuringAutomation(true);
  };

  const writeEmail = async () => {
    const prompts = useStateStore.getState().referralPrompts;
    const selected_goal_id = useStateStore.getState().currentGoalId;
    const promptData = prompts.filter(
      (item) => item.goal_id === selected_goal_id
    )[0];
    const email_data = useStateStore.getState().emailData;
    if (
      Object.keys(email_data).length > 0 &&
      email_data?.email &&
      email_data.email != ""
    ) {
      setInitialString("");
      setEmailDataString("");
      setEmailNotFound(false);
      setShowLoading(true);
      const response = await axios.post(
        `https://backend.lazyapply.com/referral/writeEmailV2`,
        {
          resume: useStateStore.getState().resumeReferralData,
          employee: employeeSelected,
          referral_job_title: promptData.referral_job_title,
          companyDetails: mainCompanySet,
          v2Id: userData?.v2Id || "",
          referralInitData: useStateStore.getState().referralInitData,
          customTemplates: useStateStore.getState().customTemplates,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowLoading(false);
      let emailTitle = `Referral For ${promptData.referral_job_title} Position`;
      if (
        emailTemplateOption == 1 ||
        (referralInitData.referral?.emailTemplateOption &&
          referralInitData.referral?.emailTemplateOption == 1)
      ) {
        emailTitle =
          customTemplates.filter(
            (obj) =>
              obj.custom_email_uuid ==
                referralInitData.referral?.customTemplateId || customTemplateId
          )[0]?.email_title ||
          `Referral For ${promptData.referral_job_title} Position`;
      }

      console.log("emailttile", emailTitle, response.data);
      setEmailTitle(emailTitle);
      if (response.data.parsed) {
        setInitialString(response.data.content);
        setEmailDataString(response.data.content);
      } else {
        console.log("some error ocurred");
      }
    } else {
      setEmailNotFound(true);
    }
  };

  const checkDisabled = () => {
    if (
      emailTemplateOption == 2 ||
      (emailTemplateOption == 1 &&
        customTemplates.length > 0 &&
        customTemplateId != "")
    ) {
      return false;
    } else {
      return true;
    }
  };

  const showActivateOrNot = async () => {
    if (
      referralInitData?.referral?.emailTemplateOption &&
      referralInitData.referral.emailTemplateOption != ""
    ) {
      setEmailTemplateOption(referralInitData.referral.emailTemplateOption);
      setCustomTemplateId(referralInitData.referral?.customTemplateId || "");
      setShowIncompleteDataError(false);

      if (!useStateStore.getState().editEmailSettings) {
        writeEmail();
      }
    } else {
      setShowIncompleteDataError(true);
    }
  };

  const showActivateOrNot1 = () => {
    if (
      referralInitData?.referral?.emailTemplateOption &&
      referralInitData.referral.emailTemplateOption != ""
    ) {
      setEmailTemplateOption(referralInitData.referral.emailTemplateOption);
      setCustomTemplateId(referralInitData.referral?.customTemplateId || "");
      setShowIncompleteDataError(false);
    } else {
      setShowIncompleteDataError(true);
    }
  };

  useEffect(() => {
    setSaveButtonClickAndSuccess(false);
  }, []);

  useEffect(() => {
    showActivateOrNot1();

    let timeout;
    const interval = setInterval(() => {
      if (
        useStateStore.getState().emailAutomationReferral &&
        useStateStore.getState().saveButtonClickAndSuccess
      ) {
        clearTimeout(timeout);
        clearInterval(interval);
        showActivateOrNot();
      }
    }, 100);

    timeout = setTimeout(() => {
      clearInterval(interval);
      showActivateOrNot();
    }, 5000);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [referralInitData, editEmailSettings]);

  return (
    <div>
      <button
        id="email-block-go-back"
        className="btn main-referral-goback"
        onClick={() => {
          setSendEmail(false);
        }}
      >
        <i className="fas fa-arrow-left mr-2"></i> Go Back
      </button>

      <div className="main-referral-email-title">
        {(showImcompleteDataError || showEmailSettings) && (
          <>
            {" "}
            <div className="main-referral-custom-template">
              <Form.Label> Choose a option to generate AI Emails</Form.Label>
              <Form.Control
                as="select"
                id="emailtemplateoption"
                value={emailTemplateOption}
                onChange={(e) => {
                  setEmailTemplateOption(parseInt(e.target.value, 10));
                  //   const prompts = useStateStore.getState().referralPrompts;
                  //   const selected_goal_id =
                  //     useStateStore.getState().currentGoalId;
                  //   const promptData = prompts.filter(
                  //     (item) => item.goal_id === selected_goal_id
                  //   )[0];
                  //   const referralData = referralInitData?.referral;
                  //   setReferralInitData({
                  //     ...referralInitData,
                  //     referral: {
                  //       ...referralData,
                  //       emailTemplateOption: e.target.value,
                  //       customTemplateId: customTemplateId,
                  //       resumeId:
                  //         useStateStore.getState().resumeReferralData.resumeId,
                  //       referralTitle: promptData.referral_job_title,
                  //     },
                  //   });
                }}
              >
                <option value={-1}>Choose a option</option>
                <option value={1}>Custom Template For Email Generation</option>
                <option value={2}>Our AI For Email Generation</option>
              </Form.Control>
              {emailTemplateOption == 1 ? (
                <>
                  {customTemplates.length > 0 ? (
                    <div className="main-referral-select-custom-template">
                      <p>Select a custom email template created by you</p>
                      <Form.Control
                        as="select"
                        id="emailtemplateoption"
                        value={customTemplateId}
                        onChange={(e) => {
                          setCustomTemplateId(e.target.value);
                        }}
                      >
                        <option value="">Choose a option</option>
                        {customTemplates.map((obj) => {
                          return (
                            <option value={obj.custom_email_uuid}>
                              {obj.template_name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </div>
                  ) : (
                    <div className="main-referral-no-templates">
                      No custom templates available,{" "}
                      <span
                        className="main-referral-email-error-createprofile"
                        onClick={() => {
                          window.open(
                            `${window.location.origin}/dashboard/custom-templates`,
                            "_blank"
                          );
                        }}
                      >
                        Create one now
                      </span>
                      <i
                        style={{ color: "white", fontSize: "16px" }}
                        className="fas fa-external-link-alt ml-2"
                      ></i>{" "}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
              <div>
                <button
                  disabled={checkDisabled()}
                  onClick={() => {
                    activeAiEmails();
                  }}
                  className="main-referral-custom-template-save-button"
                >
                  Save Your Choice{" "}
                  {activeEmailLoading && (
                    <Spinner
                      animation="border"
                      size="sm"
                      style={{ marginLeft: "10px" }}
                    />
                  )}
                </button>
              </div>
            </div>
          </>
        )}
        <PeopleBlock
          employee={employeeSelected}
          showSendEmailButton={false}
          emailData={emailData}
          EmailDataComponent={
            <>
              {Object.keys(emailData).length > 0 ? (
                <div className="main-referral-company-domain">
                  {emailData?.email && emailData.email != "" ? (
                    emailData.email
                  ) : (
                    <span style={{ color: "red" }}>Email Not Found!</span>
                  )}
                </div>
              ) : (
                <>
                  <div className="main-referral-modal-loading-email">
                    Getting Email...{" "}
                    <Spinner
                      animation="border"
                      size="sm"
                      style={{ marginLeft: "10px" }}
                    />
                  </div>
                </>
              )}
            </>
          }
        />
        {emailNotFound && (
          <p
            style={{
              margin: "20px 0px",
              color: "#007bff",
              fontWeight: "500",
            }}
          >
            Email not found, try again with another employee!
          </p>
        )}
        {showLoading && (
          <div className="main-referral-modal-loading-email-body">
            Writing Email Using AI...{" "}
            <Spinner
              animation="border"
              size="sm"
              style={{ marginLeft: "10px" }}
            />
          </div>
        )}
        <Form.Control
          style={{ marginTop: "20px" }}
          required
          disabled={
            emailNotFound || showImcompleteDataError || showEmailSettings
          }
          type="text"
          value={emailTitle}
          id="emailsubject"
          placeholder="Email Subject Here..."
          onChange={(e) => setEmailTitle(e.target.value)}
        />
      </div>
      <div className="main-referral-email-body">
        <EmailComposer
          setInitialString={setInitialString}
          showEmailSettings={showEmailSettings}
          initialString={initialString}
          emailNotFound={emailNotFound}
          showImcompleteDataError={showImcompleteDataError}
        ></EmailComposer>
      </div>
      <div className="main-referral-emailsend-button">
        <button
          className="main-referral-select-all"
          disabled={showLoading || regenerateLoading || showEmailSettings}
          onClick={async () => {
            setInitialString("");
            setRegenerateLoading(true);
            await writeEmail();
            setRegenerateLoading(false);
          }}
        >
          Regenerate Email
          {regenerateLoading && (
            <Spinner
              animation="border"
              size="sm"
              style={{ marginLeft: "10px" }}
            />
          )}
        </button>
      </div>
    </div>
  );
}

export default EmailBlock;
