import React, { useState, useEffect } from "react";
import "./dashboard.css";
import { Switch, Route, Redirect } from "react-router-dom";
import XYZ1 from "../referral/XYZ1.js";
import XYZ2 from "../referral/XYZ2.js";
import XYZPricing from "../referral/xyzpricing.js";
import PaymentSuccess from "../paymentsuccess";
import CustomTemplate from "../referral/CustomTemplate.js";
import EmailSettings from "../referral/emailSettings.js";
import { useStateStore } from "../../store";
import { Alert } from "react-bootstrap";
import axios from "axios";

import AtsJobs from "../ats-jobs/atsJobs.js";

function Dashboard() {
  const { token } = useStateStore();
  const ifPlanExist = () => {
    const user_plan_details = useStateStore.getState().userPlanDetails || {};
    if (
      Object.keys(user_plan_details).length === 0 ||
      (user_plan_details.currentPlan &&
        !user_plan_details.currentPlan.planStarted)
    ) {
      return 0;
    } else {
      return 1;
    }
  };

  const checkUserPlan = () => {
    axios
      .get("https://backend.lazyapply.com/getUserDetails", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const planStarted = response.data?.planDetails?.planStarted || 0;
        console.log("planstarted", planStarted);
        setCheckIfPreviousUser(planStarted);
        setShowAlert(planStarted);
      })
      .catch((err) => {
        console.log("error");
      });
  };

  const [checkIfPreviousUser, setCheckIfPreviousUser] = useState(false);

  useEffect(() => {
    if (token) {
      checkUserPlan();
    }
  }, [token]);

  const [showAlert, setShowAlert] = useState(false);

  return (
    <div className="dashboard">
      <div className="dashboardRight">
        {checkIfPreviousUser ? (
          <>
            <Alert
              show={showAlert}
              onClose={() => setShowAlert(!showAlert)}
              dismissible
              style={{ position: "fixed" }}
            >
              <Alert.Heading>
                For Our Old Lazyapply Customers, The Dashboard Link Is at
                <a
                  href="https://app.lazyapply.com"
                  style={{
                    color: "black",
                    marginLeft: "10px",
                  }}
                >
                  https://app.lazyapply.com
                </a>
                <span
                  className="mainTimeBuy"
                  onClick={() => {
                    window.open("https://app.lazyapply.com", "_blank");
                  }}
                >
                  Click Here
                </span>
              </Alert.Heading>
            </Alert>
          </>
        ) : (
          <></>
        )}
        <Switch>
          <Route exact path="/dashboard/aisearch">
            <XYZ1 />
          </Route>
          <Route exact path="/dashboard/goal">
            <XYZ2 />
          </Route>
          <Route exact path="/dashboard/pricing">
            <XYZPricing />
          </Route>
          <Route exact path={`/dashboard`}>
            <XYZ1 />
          </Route>
          {/* {ifPlanExist() && ( */}
          <Route exact path={`/dashboard/custom-templates`}>
            <CustomTemplate></CustomTemplate>
          </Route>
          {/* )} */}
          {/* <Route exact path={`/dashboard/email-settings`}>
            <EmailSettings></EmailSettings>
          </Route> */}
          <Route exact path={`/dashboard/payment-success`}>
            <PaymentSuccess />
          </Route>

          <Route exact path={`/dashboard/ats-jobs`}>
            <AtsJobs />
          </Route>

          <Route>
            <Redirect to="/dashboard" />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default Dashboard;
