import React from "react";
import ExpandableText from "../referral/IsExplandableText";
import "./CompanyCard.css";

const CompanyCard = ({ job }) => {
  return (
    <div className="main-referral-container">
      <div className="main-referral-top">
        <div className="main-referral-top-first">
          <img
            alt="company_logo"
            src={job?.image || "/assets/no_image_available.png"}
            className="navbarImg"
          />
        </div>
        <div className="main-referral-top-second">
          <div className="main-referral-company-name">
            {job?.title || "No Job Name"}
          </div>
          <div className="main-referral-company-domain main-domain">
            {job?.link || "No Domain Available"}
          </div>
          <div className="main-referral-company-otherdetails">
            {job?.jobBoard || "No Job Board Info"}
          </div>
        </div>
      </div>
      <div className="main-referral-bottom">
        <div>
          <ExpandableText
            text={
              job?.description ||
              job?.descriptionShort ||
              "No Description Found"
            }
            maxLength={300}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyCard;
