import React, { useState } from "react";

function ExpandableText({ text, maxLength }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const renderText = () => {
    if (isExpanded || text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  return (
    <div>
      <p className="main-referral-description">
        {renderText()}
        {text.length > maxLength && (
          <span
            style={{ fontWeight: "bold", cursor: "pointer" }}
            onClick={toggleIsExpanded}
          >
            {isExpanded ? "  Less" : "More"}
          </span>
        )}
      </p>
    </div>
  );
}

export default ExpandableText;
