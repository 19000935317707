import React, { useState } from "react";
import SearchBar from "./SearchBar";
import CompanyCard from "./CompanyCard";
import axios from "axios";

export default function AtsJobs() {
  const [jobs, setJobs] = useState([]);

  const handleSearch = async (searchTerm) => {
    try {
      console.log(searchTerm);

      const response = await axios.post(
        "https://backend.lazyapply.com/referral/getJobLinks/",
        { search: searchTerm }
      );
      setJobs(response.data.jobs);
    } catch (error) {
      console.error("Error fetching links:", error);
    }
  };

  return (
    <div>
      <SearchBar onSearch={handleSearch} />
      <div>
        <button
          id="ats-jobs-autofill"
          style={{ float: "right", marginRight: "100px", marginTop: "20px" }}
          className="main-referral-select-all-start-automation"
        >
          Start Automation
        </button>
      </div>
      <div className="main-referral-cards-container">
        {jobs.map((job, index) => (
          <CompanyCard key={index} job={job} />
        ))}
      </div>
    </div>
  );
}
