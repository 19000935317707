import React from 'react';
import './ProgressBar.css'; 

const ProgressBar = () => {
  return (
    <div className="progress_bar_lazyApply"></div>
  );
}

export default ProgressBar;
