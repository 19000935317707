import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Spinner, Alert } from "react-bootstrap";
import axios from "axios";
import { useStateStore } from "../../store/index";
import { v1 as uuidv1 } from "uuid";
import FileUpload from "./FileUploader";
import { useHistory } from "react-router-dom";
import countryOptions from "../../data/countries.json";
function CreateResumeModal({
  showProp,
  hideShow,
  setShowInfoModal,
  setInfoModalType,
  performTask2,
}) {
  const [show, setShow] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const history = useHistory();
  const [value, setValue] = useState("");
  const [file, setFile] = useState(null);
  const [typeOfMessage, setTypeOfMessage] = useState("success");
  const {
    currentGoalId,
    userData,
    setUserResumeV2IndividualV3,
    token,
    resumeValidation,
    setResumeValidation,
    setResumeReferralData,
    resumeReferralData,
  } = useStateStore();

  const [mainfileDetails, setMainFileDetails] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address, setAddress] = useState("");
  const [resumeName, setResumeName] = useState("");
  const [errors, setErrors] = useState({});
  const [showMessage, setShowMessage] = useState("");
  const [disable, setDisable] = useState(false);

  const requiredFields = [
    "firstName",
    "lastName",
    "country",
    "zipCode",
    "address",
  ];

  const buttonSave = {
    backgroundColor: "slateblue",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "10px 20px",
    textAlign: "center",
    borderRadius: "10px",
    width: "100%",
    outline: "none",
    border: "none",
    marginTop: "50px",
  };

  const showr = {
    textAlign: "center",
    fontWeight: "500",
    color: "orangered",
    fontSize: "18px",
    marginTop: "10px",
  };

  const showCustomMessage = (message) => {
    setShowMessage(message);
    setTimeout(() => {
      setShowMessage("");
    }, 2500);
  };

  const handleClose = () => {
    setShow(false);
    hideShow();
  };

  const createNewResume = () => {
    setDisable(true);
    const v2Id = userData?.v2Id || "";
    const newResumeId = uuidv1();
    if (value !== "" && file) {
      const formData = new FormData();
      formData.append("goalId", currentGoalId);
      formData.append("resumename", value);
      formData.append("resumeId", newResumeId);
      formData.append("file", file);
      setShowSpinner(true);
      let url = `https://backend.lazyapply.com/referral/uploadResume/${v2Id}`;
      axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setShowSpinner(false);
          if ("resumeData" in response.data) {
            setTypeOfMessage("success");
            showCustomMessage("Successfully Created");
            setDisable(false);
            // handleClose();
            setFirstName(response.data.resumeData.firstName);
            setLastName(response.data.resumeData.lastName);
            setResumeName(response.data.resume.resumeName);
            setMainFileDetails(response.data.resume.mainfileDetails);
            let countryMain = "";
            if (response.data.resumeData.country != "") {
              countryMain = countryOptions.find((element) =>
                element.name.includes(response.data.resumeData.country)
              );
              setCountry(countryMain?.code || "");
            }
            setZipCode(response.data.resumeData.zipCode);
            setAddress(response.data.resumeData.address);
            setResumeReferralData({ ...resumeReferralData, validation: 1 });
            setTimeout(() => {
              setResumeValidation(1);
            }, 500);
          } else if ("error" in response.data) {
            setTypeOfMessage("error");
            showCustomMessage(response.data.message);
            setFile(null);
            setDisable(false);
          }
        })
        .catch((error) => {
          console.log("error", error);
          setShowSpinner(false);
          setDisable(false);
          setTypeOfMessage("error");
          showCustomMessage("Some error occurred, please try again!");
        });
    } else {
      setDisable(false);
      setTypeOfMessage("error");
      showCustomMessage(
        file
          ? "Resume name cannot be empty!"
          : "Please upload your resume / cv!"
      );
    }
  };

  const submitCoupanCode = (e) => {
    e.preventDefault();
    createNewResume();
  };

  const resetResumeFile = () => {
    setFile(null);
  };

  const validateUserDetails = () => {
    const newErrors = {};
    requiredFields.forEach((field) => {
      if (!eval(field)) {
        newErrors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const submitUserDetails = () => {
    if (validateUserDetails()) {
      console.log({
        firstName,
        lastName,
        country,
        zipCode,
        address,
      });
      // showCustomMessage("Details submitted successfully!");
      const v2Id = userData?.v2Id || "";
      let url = `https://backend.lazyapply.com/referral/updateResumeDetails/${v2Id}`;
      axios
        .post(
          url,
          {
            goalId: currentGoalId,
            resumeData: {
              firstName,
              lastName,
              country,
              zipCode,
              address,
            },
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log("response updating resume details", response);
          handleClose();
          setShowInfoModal(true);
          setInfoModalType("uploadComplete");
          setResumeReferralData({ ...resumeReferralData, validation: 1 });
          setTimeout(() => {
            setShowInfoModal(false);
            performTask2();
          }, 2000);
        })
        .catch((err) => {
          console.log("error updating resume details", err);
          handleClose();
        });
    } else {
      console.log("here");
    }
  };

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  useEffect(() => {
    if (
      Object.keys(resumeReferralData).length > 0 &&
      resumeReferralData?.resumeData?.firstName
    ) {
      console.log("resumereferraldata", resumeReferralData);

      setFirstName(resumeReferralData?.resumeData?.firstName || "");
      setLastName(resumeReferralData?.resumeData?.lastName || "");
      setResumeName(resumeReferralData?.resumeName || "");
      setMainFileDetails(resumeReferralData?.mainfileDetails || {});
      let countryMain = "";
      if (
        resumeReferralData?.resumeData?.country &&
        resumeReferralData?.resumeData.country != ""
      ) {
        countryMain = countryOptions.find((element) =>
          element.name.includes(resumeReferralData.resumeData.country)
        );
        setCountry(countryMain?.code || "");
      }
      setZipCode(resumeReferralData?.resumeData?.zipCode || "");
      setAddress(resumeReferralData?.resumeData?.address || "");
    }
  }, [resumeReferralData]);

  async function downloadFileFromObject(fileObject) {
    const cacheBustedUrl = `${fileObject.location}?_=${Date.now()}`;
    console.log("Attempting to fetch URL:", cacheBustedUrl);

    try {
      const response = await fetch(cacheBustedUrl);

      if (!response.ok) {
        throw new Error(
          `Network response was not ok. Status: ${response.status}`
        );
      }

      const fileContent = await response.blob();
      const blob = new Blob([fileContent], { type: fileObject.mimetype });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.target = "_blank";
      link.download = fileObject.originalname;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Failed to fetch the file:", error);
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="custom-modal-width"
      backdrop="static"
      className="template-modal-main"
    >
      <Modal.Header closeButton={!resumeValidation}>
        <Modal.Title>
          {resumeValidation === 0
            ? "Upload Your Resume / CV"
            : "Check Your Profile Details"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: "20px", maxWidth: "100%" }}>
          {resumeValidation === 0 ? (
            <>
              <Form onSubmit={submitCoupanCode}>
                <Form.Control
                  required
                  type="text"
                  id="resumename"
                  value={value}
                  placeholder="Resume Name, ex. -> Aman_Software_Resume"
                  onChange={(e) => setValue(e.target.value)}
                />

                <div className="resumeTrackerUpload">
                  <p style={{ marginTop: "20px" }}>
                    Upload your Resume/ CV{" "}
                    {file && (
                      <span onClick={resetResumeFile} className="resetResume">
                        Change Resume
                      </span>
                    )}
                  </p>

                  <FileUpload file={file} setFile={setFile} />
                </div>

                {file && (
                  <p style={{ color: "green", marginTop: "10px" }}>
                    Resume Uploaded Successfully! Press{" "}
                    <span
                      style={{
                        color: "slateblue",
                        fontWeight: "bold",
                      }}
                    >
                      create now
                    </span>{" "}
                    button
                  </p>
                )}

                <button style={buttonSave} disabled={disable}>
                  Create Now{" "}
                  {showSpinner && (
                    <Spinner
                      animation="border"
                      size="sm"
                      style={{ marginLeft: "5px" }}
                    />
                  )}
                </button>
              </Form>
              <p
                className={"showr" + (typeOfMessage == "error" ? " error" : "")}
              >
                {showMessage}
              </p>
            </>
          ) : (
            <>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitUserDetails();
                }}
              >
                <div className="row mainformuploadresume">
                  <div className="col-6">
                    <Form.Group controlId="firstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        isInvalid={!!errors.firstName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        isInvalid={!!errors.lastName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-6">
                    <Form.Group controlId="country">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        as="select"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        isInvalid={!!errors.country}
                      >
                        <option value="">Select Country</option>
                        {countryOptions.map((option) => (
                          <option key={option.code} value={option.code}>
                            {option.name}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.country}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-6">
                    <Form.Group controlId="zipCode">
                      <Form.Label>Zip Code</Form.Label>
                      <Form.Control
                        type="text"
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                        isInvalid={!!errors.zipCode}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.zipCode}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="address">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        isInvalid={!!errors.address}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>

                <div className="uploadbox">
                  <p
                    style={{
                      color: "slateblue",
                    }}
                  >
                    Your Uploaded Resume{" "}
                  </p>
                  <div className="resume-view">
                    <div className="resume-view-img">
                      <img src={"/assests/pdf.svg"}></img>
                    </div>
                    <div className="resume-view-label">
                      {mainfileDetails?.originalname || ""}
                      {"  "}
                      {resumeName != "" ? resumeName : ""}
                    </div>

                    <div
                      className="resume-view-cancel"
                      onClick={() => {
                        downloadFileFromObject(mainfileDetails);
                      }}
                    >
                      View
                    </div>
                  </div>
                </div>

                <Button
                  variant="primary"
                  type="submit"
                  className="mt-3"
                  style={buttonSave}
                >
                  Submit Details And Continue Automation
                </Button>
              </Form>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CreateResumeModal;
