import React, { useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./xyzdropdown.css";

const ChatGPTDropdown = ({ dropdownOptions, selectedOption, setSelectedIndex }) => {
  const [selectedModel, setSelectedModel] = useState(
    dropdownOptions[selectedOption]?.goal_text || ""
  );

  const handleSelect = (eventKey, index) => {
    setSelectedModel(eventKey);
    setSelectedIndex(index);
  };

  // Utility function to truncate text to four words and add ellipsis if needed
  const truncateText = (text) => {
    const words = text.split(" ");
    return words.length > 5 ? words.slice(0, 5).join(" ") + "..." : text;
  };

  const options = dropdownOptions;

  return (
    <Dropdown>
      <Dropdown.Toggle id="chatgpt-dropdown" className="custom-dropdown-toggle">
        {truncateText(selectedModel)}
      </Dropdown.Toggle>

      <Dropdown.Menu className="custom-dropdown-menu">
        {/* Add an informational label at the top */}
        <div className="dropdown-info-label">
          <strong>Goals</strong>
          <p>Select your preferred job goal:</p>
        </div>

        {options.map((option, index) => {
          const truncatedOption = truncateText(option.goal_text);
          const shouldShowTooltip = option.goal_text.split(" ").length > 4;
          const isSelected = dropdownOptions[selectedOption]?.goal_text === option.goal_text;


          console.log('dropdownOptions[selectedOption]?.goal_text',dropdownOptions[selectedOption]?.goal_id)
          return shouldShowTooltip ? (
            <OverlayTrigger
              key={option.goal_id}
              placement="right"
              overlay={<Tooltip id={`tooltip-${option.goal_id}`}>{option.goal_text}</Tooltip>}
            >
              <Dropdown.Item
                eventKey={option.goal_text}
                onClick={() => handleSelect(option.goal_text, index)}
                className={isSelected ? "selected-item" : ""}
              >
                {truncatedOption}
                {isSelected && (
                  <i
                    className="fas fa-check"
                    style={{ marginLeft: "10px", color: "#007bff" }}
                  ></i>
                )}
              </Dropdown.Item>
            </OverlayTrigger>
          ) : (
            <Dropdown.Item
              eventKey={option.goal_text}
              key={option.goal_id}
              onClick={() => handleSelect(option.goal_text, index)}
              className={isSelected ? "selected-item" : ""}
            >
              {truncatedOption}
              {isSelected && (
                <i
                  className="fas fa-check"
                  style={{ marginLeft: "10px", color: "#007bff" }}
                ></i>
              )}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ChatGPTDropdown;
