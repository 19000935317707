import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useStateStore } from "../../store";
import ReferralMainBlockModal from "./ReferralMainBlockModal";
import { mixpanel } from "../../index";

function ReferralModal({ s, closeFn }) {
  const setSendEmail = useStateStore((state) => state.setSendEmail);
  const mainModalTitle = useStateStore((state) => state.mainModalTitle);
  const [show, setShow] = useState(false);
  const userData = useStateStore((state) => state.userData);
  const setShowReferralFeedback = useStateStore(
    (state) => state.setShowReferralFeedback
  );
  const stepNoReferralFeedback = useStateStore(
    (state) => state.stepNoReferralFeedback
  );
  const emailAutomationReferral = useStateStore(
    (state) => state.emailAutomationReferral
  );

  const handleClose = () => {
    setShow(false);
    closeFn();
  };

  useEffect(() => {
    setShow(s);
  }, [s]);

  return (
    <Modal
      className="main-referral-modal"
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      backdrop="static"
    >
      {/* <Modal.Header closeButton className="main-referral-modal-header">
        <Modal.Title>{mainModalTitle}</Modal.Title>
      </Modal.Header> */}
      {emailAutomationReferral ? (
        <></>
      ) : (
        <div
          className="main-referral-modal-header-cross"
          onClick={() => {
            handleClose();

            setSendEmail(false);
          }}
        >
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
      )}

      <Modal.Body className="main-referral-modal-body">
        <ReferralMainBlockModal />
      </Modal.Body>
    </Modal>
  );
}

export default ReferralModal;
