import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

function SendEmailSuccess({ s, closeFn }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    closeFn();
  };

  useEffect(() => {
    setShow(s);
  }, [s]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="md"
      id="modal_email_sent_success"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>Email Sent Successfully</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-connect-email">
        <img src={"/assests/success_tick.webp"} className="navbarImg"></img>
        <div className="modal-email-sent-success-div">
          You can verify that yourself in your GMAIL app in sent section OR by{" "}
          <span
            onClick={() => {
              window.open(
                "https://mail.google.com/mail/u/0/?tab=rm&ogbl#sent",
                "_blank"
              );
            }}
          >
            clicking here
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SendEmailSuccess;
