import React, { useState, useEffect } from "react";
import { useStateStore } from "../../store";
import ExpandableText from "./IsExplandableText";
import "./ReferralMainBlock.css";
import ReferralPeopleList from "./ReferralPeopleList";
import { mixpanel } from "../../index";
import { Form } from "react-bootstrap";

function ReferralMainBlock({
  setShow = () => {},
  setSendEmail,
  company,
  showSendEmail = true,
  mixpanel_page,
  index = 0,
}) {
  const userData = useStateStore((state) => state.userData);
  const setStepNoReferralFeedback = useStateStore(
    (state) => state.setStepNoReferralFeedback
  );
  const setEmployeesMain = useStateStore((state) => state.setEmployees);
  const setMainCompanySet = useStateStore((state) => state.setMainCompanySet);
  const setMainModalTitle = useStateStore((state) => state.setMainModalTitle);
  const setButtonClick = useStateStore((state) => state.setButtonClick);
  let industries = (company?.industries || [])
    .map((industry) => {
      if (industry === company.industryMain) {
        return { industry, main: 1 };
      } else {
        return { industry, main: 0 };
      }
    })
    .sort((a, b) => b.main - a.main);

  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const displayedIndustries = showAll ? industries : industries.slice(0, 5);

  const { selectedCompanies, addCompany, removeCompany } = useStateStore();

  const handleSelectionToggle = (index) => {
    if (selectedCompanies.includes(index)) {
      removeCompany(index);
    } else {
      addCompany(index);
    }
  };

  useEffect(() => {
    setStepNoReferralFeedback(1);
  }, []);

  return (
    <>
      {false ? (
        <div className="main-referral-container">
          <>
            <div className="main-referral-top">
              <div className="main-referral-top-first">
                <img
                  alt="company_logo"
                  src={company?.logo || "/assests/no_image_available.png"}
                  className="navbarImg"
                ></img>
              </div>
              <div className="main-referral-top-second">
                <div className="main-referral-company-name">
                  {company.name}{" "}
                  {company?.socialNetworks?.linkedin &&
                    company.socialNetworks.linkedin != "" && (
                      <i
                        className="fab fa-linkedin"
                        onClick={() => {


                          window.open(
                            company.socialNetworks.linkedin,
                            "_blank"
                          );
                        }}
                      ></i>
                    )}
                </div>
                <div className="main-referral-company-domain main-domain">
                  {company?.domain || ""}
                </div>
                <div className="main-referral-company-otherdetails">
                  Employees - {company?.totalEmployees || "Not Found"}, Country
                  - {company?.country?.name || "unknown"}
                  {/* , Revenue - {company.revenue}, */}
                </div>
              </div>
              {showSendEmail && (
                <div className="main-referral-top-third">
                  <button
                    id="main-ask-referral-button"
                    onClick={() => {
                      setButtonClick(false);
                      setEmployeesMain([]);

                      setShow(true);
                      setMainModalTitle(`Apply To ${company.name}`);
                      setMainCompanySet(company);
                    }}
                  >
                    Ask For Referral
                  </button>
                </div>
              )}
            </div>
            <div className="main-referral-bottom">
              <div>
                <ExpandableText
                  text={
                    company?.description ||
                    company?.descriptionShort ||
                    "No Description Found"
                  }
                  maxLength={300}
                />
              </div>
              <div className="main-referral-tags">
                {displayedIndustries.map((industry, index) => (
                  <div
                    key={index}
                    className={
                      "main-referral-tag-content" +
                      (industry.main === 1
                        ? " main-referral-tag-content-main"
                        : "")
                    }
                  >
                    {industry.industry}
                  </div>
                ))}
                {industries.length > 5 && (
                  <div
                    onClick={toggleShowAll}
                    className="main-referral-tag-content main-referral-tag-content-button"
                  >
                    {showAll ? "Show Less" : "Show More"}
                  </div>
                )}
              </div>
            </div>
          </>
        </div>
      ) : (
        <>
          {showSendEmail && (
            <div className="col-lg-4">
              <div
                className={`main-referral-container main-referral-container_new_format ${
                  selectedCompanies.includes(index) ? "selected" : ""
                }`}
                onClick={() => handleSelectionToggle(index)}
                style={{ cursor: "pointer" }}
              >
                <Form.Check
                  type="checkbox"
                  className="custom-checkbox"
                  checked={selectedCompanies.includes(index)}
                  onChange={() => handleSelectionToggle(index)}
                />

                <div className="main-referral-top">
                  <button
                    id="main-ask-referral-button"
                    style={{
                      width: "0px",
                      height: "0px",
                      visibility: "hidden",
                    }}
                    onClick={() => {
                      setButtonClick(false);
                      setEmployeesMain([]);

                      setShow(true);
                      setMainModalTitle(`Apply To ${company.name}`);
                      setMainCompanySet(company);
                    }}
                  >
                    Ask For Referral
                  </button>
                  <div className="main-referral-top-first">
                    <img
                      alt="company_logo"
                      src={company?.logo || "/assests/no_image_available.png"}
                      className="navbarImg"
                    ></img>
                  </div>
                  <div className="main-referral-top-second">
                    <div className="main-referral-company-name">
                      {company.name}{" "}
                    </div>
                    <div className="main-referral-company-domain main-domain">
                      {company?.domain || ""}
                    </div>
                    <div className="main-referral-company-otherdetails">
                      {company?.country?.name || ""}
                    </div>
                  </div>
                </div>
                <div className="main-referral-bottom">
                  <div>
                    <ExpandableText
                      text={
                        company?.description ||
                        company?.descriptionShort ||
                        "No Description Found"
                      }
                      maxLength={300}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {!showSendEmail && (
            <>
              <div className="main-referral-container">
                <>
                  <div className="main-referral-top">
                    <div className="main-referral-top-first">
                      <img
                        alt="company_logo"
                        src={company?.logo || "/assests/no_image_available.png"}
                        className="navbarImg"
                      ></img>
                    </div>
                    <div className="main-referral-top-second">
                      <div className="main-referral-company-name">
                        {company.name}{" "}
                      </div>
                      <div className="main-referral-company-domain main-domain">
                        {company?.domain || ""}
                      </div>
                      <div className="main-referral-company-otherdetails">
                        {company?.country?.name || ""}
                      </div>
                    </div>
                  </div>
                  <div className="main-referral-bottom">
                    <div>
                      <ExpandableText
                        text={
                          company?.description ||
                          company?.descriptionShort ||
                          "No Description Found"
                        }
                        maxLength={300}
                      />
                    </div>
                  </div>
                </>
                <ReferralPeopleList setSendEmail={setSendEmail} />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default ReferralMainBlock;
