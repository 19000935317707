import React, { useState } from "react";
import { Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./SearchBar.css";

const SearchBar = ({ onSearch }) => {
  const [searchValue, setSearchValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchValue.trim()) {
      onSearch(searchValue);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      handleSearch(e);
    }
  };

  return (
    <Form onSubmit={handleSearch}>
      <InputGroup
        className={`main-referral-search-input-group ${
          isFocused ? "focused" : ""
        }`}
      >
        <Form.Control
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          as="textarea"
          className="main-referral-search-bar"
          id="search-bar-ats-jobs"
          placeholder="Search Lazyapply AI..."
          value={searchValue}
          onKeyDown={handleKeyDown}
          onChange={(e) => setSearchValue(e.target.value)}
          style={{
            height: "60px",
            overflow: "hidden",
            resize: "none",
            padding: "15px 10px",
            boxSizing: "border-box",
          }}
        />
        <div
          className="main-referral-search-input-group-submit"
          onClick={handleSearch}
          disabled={!searchValue.trim()}
        >
          <i className="fas fa-arrow-up"></i>
        </div>
      </InputGroup>
    </Form>
  );
};

export default SearchBar;
