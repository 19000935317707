import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function EmailComposer({
  setInitialString,
  showEmailSettings,
  initialString,
  emailNotFound,
  showImcompleteDataError,
}) {
  const [displayedContent, setDisplayedContent] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const quillRef = useRef(null);
  const [isInitialComplete, setIsInitialComplete] = useState(false);

  useEffect(() => {
    if (initialString && !isInitialComplete) {
      setDisplayedContent("");
      setEditorContent(""); 
      const words = initialString.split(" ");
      let wordIndex = 0;

      const intervalId = setInterval(() => {
        if (wordIndex < words.length) {
          setDisplayedContent(
            (prev) => prev + (wordIndex > 0 ? " " : "") + words[wordIndex]
          );
          wordIndex++;
        } else {
          clearInterval(intervalId);
          setEditorContent(initialString);
          setIsInitialComplete(true);
        }
      }, 100);

      return () => clearInterval(intervalId);
    }
  }, [initialString, isInitialComplete]);

  useEffect(() => {
    if (quillRef.current && quillRef.current.getEditor) {
      const editor = quillRef.current.getEditor();
      const editorElement = editor.container.querySelector(".ql-editor");
      editorElement.scrollTop = editorElement.scrollHeight;
    }
  }, [displayedContent]);

  const handleChange = (value) => {
    setEditorContent(value);
  };

  const handleChange1 = (value) => {
    setEditorContent(value);
    setInitialString(value);
  };

  const containerHeight = showImcompleteDataError ? 100 : 300;
  const containerBackgroundColor =
    emailNotFound || showImcompleteDataError ? "#e9ecef" : "white";

  return (
    <div>
      {isInitialComplete ? (
        <>
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={editorContent}
            onChange={handleChange1}
            placeholder="Email Body Here..."
            style={{
              height: `${containerHeight}px`,
              overflowY: "auto",
              backgroundColor: containerBackgroundColor,
              border: "2px solid #ccc",
            }}
          />
        </>
      ) : (
        <>
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={displayedContent}
            onChange={handleChange}
            placeholder="Email Body Here..."
            readOnly={
              emailNotFound || showImcompleteDataError || showEmailSettings
            }
            style={{
              height: `${containerHeight}px`,
              overflowY: "auto",
              backgroundColor: containerBackgroundColor,
              border: "2px solid #ccc",
            }}
          />
        </>
      )}
    </div>
  );
}

export default EmailComposer;
