import React, { useEffect, useState } from "react";
import { Container, Image } from "react-bootstrap";

const steps = [
  {
    text: "Scanning Resume",
    img: "/assests/document-scanning.gif",
    width: "250px",
    height: "200px",
  },
  {
    text: "Analyzing Query",
    img: "/assests/scanning.gif",
    width: "180px",
    height: "180px",
  },
  {
    text: "Opening Websites With Latest Company Info",
    img: "/assests/website-opening.gif",
    width: "120px",
    height: "120px",
  },
  {
    text: "Finding Matching Companies",
    img: "/assests/finding-jobs.gif",
    width: "220px",
    height: "200px",
  },
  {
    text: "Companies Found",
    img: "/assests/success_gif.gif",
    width: "250px",
    height: "200px",
  },
];

const DynamicSteps = ({ companies, setLoadingFinish }) => {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    let interval;

    if (currentStep < steps.length - 1) {
      if (currentStep === 3 && !(companies.length > 0)) {
        // Stay on step 4 until isMatchingComplete is true
        return;
      }

      // Progress to the next step after 2.5 seconds
      interval = setTimeout(() => {
        setCurrentStep((prevStep) => prevStep + 1);
      }, 2500);
    } else if (currentStep === steps.length - 1) {
      // When on the last step, set loading finish after 2.5 seconds
      interval = setTimeout(() => {
        setLoadingFinish(true);
      }, 1500);
    }

    return () => clearTimeout(interval);
  }, [currentStep, companies, setLoadingFinish]);

  return (
    <Container className="main-referral-loading-companies d-flex flex-column align-items-center justify-content-center">
      <Container className="main-referral-loading-companies-img d-flex flex-column align-items-center justify-content-center">
        <Image
          src={steps[currentStep].img}
          alt={steps[currentStep].text}
          style={{
            marginBottom: "20px",
            width: steps[currentStep].width,
            height: steps[currentStep].height,
          }}
        />
      </Container>
      <div className="dynamic-text">{steps[currentStep].text}</div>
    </Container>
  );
};

export default DynamicSteps;
