import React, { useState, useEffect } from "react";
import { Button, Form, ListGroup, Row, Col, Card } from "react-bootstrap";
import axios from "axios";
import { v1 as uuidv1 } from "uuid";
import { useStateStore } from "../../store/index";
import "./CustomTemplates.css";
import { useHistory } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import TemplateModal from "./TemplateModal";

const CustomTemplates = () => {
  const history = useHistory();
  const [templates, setTemplates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("create");
  const [currentTemplate, setCurrentTemplate] = useState({
    email_title: "",
    email_description: "",
    custom_email_uuid: "",
  });

  const [templateDynamicValues, setTemplateDynamicValues] = useState({});

  const userData = useStateStore((state) => state.userData);
  const token = useStateStore((state) => state.token);

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(
        "https://backend.lazyapply.com/referral/fetchCustomTemplates",
        {
          params: { v2Id: userData?.v2Id || "" },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTemplates(response.data.templates);
    } catch (error) {
      console.error("Error fetching templates", error);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleShowModal = (mode, template = {}) => {
    setModalMode(mode);
    if (mode === "create") {
      setCurrentTemplate({
        email_title: "",
        email_description: "",
        custom_email_uuid: uuidv1(),
      });
    } else {
      setCurrentTemplate(template);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentTemplate({
      email_title: "",
      email_description: "",
      custom_email_uuid: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentTemplate((prev) => ({ ...prev, [name]: value }));
  };

  const handleDescriptionChange = (htmlContent) => {
    setCurrentTemplate((prev) => ({ ...prev, email_description: htmlContent }));
  };

  const handleDynamicValueChange = (uuid, e) => {
    const { name, value } = e.target;
    setTemplateDynamicValues((prev) => ({
      ...prev,
      [uuid]: {
        ...prev[uuid],
        [name]: value,
      },
    }));
  };

  const handleDeleteTemplate = async (uuid) => {
    try {
      await axios.post(
        `https://backend.lazyapply.com/referral/deleteCustomTemplate/${uuid}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchTemplates();
    } catch (error) {
      console.error("Error deleting template", error);
    }
  };

  const handleSaveTemplate = async () => {
    if (
      !currentTemplate.template_name ||
      !currentTemplate.email_title ||
      !currentTemplate.email_description
    ) {
      alert("Template Name, Email Title, and Email Description are required.");
      return;
    }

    try {
      if (modalMode === "create") {
        await axios.post(
          "https://backend.lazyapply.com/referral/createCustomTemplate",
          {
            ...currentTemplate,
            email: userData.email,
            v2Id: userData?.v2Id || "",
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else if (modalMode === "update") {
        await axios.post(
          `https://backend.lazyapply.com/referral/updateCustomTemplate/${currentTemplate.custom_email_uuid}`,
          currentTemplate,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
      fetchTemplates();
      handleCloseModal();
    } catch (error) {
      console.error("Error saving template", error);
    }
  };

  function replacePlaceholdersPlain(template, dynamicValues) {
    return template
      .replace(
        /{{company_name}}/g,
        dynamicValues.company_name || "{{company_name}}"
      )
      .replace(/{{job_role}}/g, dynamicValues.job_role || "{{job_role}}")
      .replace(
        /{{hiring_person_name}}/g,
        dynamicValues.hiring_person_name || "{{hiring_person_name}}"
      );
  }

  const replacePlaceholders = (template, uuid) => {
    const dynamicValues = templateDynamicValues[uuid] || {};
    let result = template
      .replace(
        /{{company_name}}/g,
        dynamicValues.company_name || "{{company_name}}"
      )
      .replace(/{{job_role}}/g, dynamicValues.job_role || "{{job_role}}")
      .replace(
        /{{hiring_person_name}}/g,
        dynamicValues.hiring_person_name || "{{hiring_person_name}}"
      );

    result = result.replace(/{{(.*?)}}/g, (match) => {
      return `<span className="placeholder">${match}</span>`;
    });

    return result;
  };

  function capitalizeFirstLetter(str) {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const goback = () => {
    history.goBack();
  };

  return (
    <div
      className={
        "custom-template-container" +
        (templates.length === 0 ? " custom-template-container-empty" : "")
      }
    >
      <button className="goal-back-button" onClick={goback}>
        back
      </button>

      {templates.length === 0 ? (
        <Card className="empty-template-card">
          <Card.Body className="text-center">
            <Card.Title>No Templates Available</Card.Title>
            <Card.Text>You don't have any custom templates yet.</Card.Text>
            <Button onClick={() => handleShowModal("create")} variant="primary">
              Create Custom Template
            </Button>
          </Card.Body>
        </Card>
      ) : (
        <>
          <Row className="mb-3">
            <Col className="text-right">
              <button
                className="main-referral-select-all-start-automation"
                onClick={() => handleShowModal("create")}
              >
                Create Custom Template
              </button>
            </Col>
          </Row>
          <div className="row">
            {templates.map((template) => (
              <div className="col-4">
                <div
                  key={template.custom_email_uuid}
                  className="email-template"
                >
                  <Row>
                    <Col md={12}>
                      <div className="email-header">
                        <h4>{capitalizeFirstLetter(template.template_name)}</h4>
                        <h5>
                          {/* Use the plain text placeholder replacement function for the title */}
                          {replacePlaceholdersPlain(template.email_title, {
                            company_name:
                              templateDynamicValues[template.custom_email_uuid]
                                ?.company_name || "",
                            job_role:
                              templateDynamicValues[template.custom_email_uuid]
                                ?.job_role || "",
                            hiring_person_name:
                              templateDynamicValues[template.custom_email_uuid]
                                ?.hiring_person_name || "",
                          })}
                        </h5>
                      </div>
                      <div
                        className="email-body"
                        dangerouslySetInnerHTML={{
                          __html: replacePlaceholders(
                            template.email_description,
                            template.custom_email_uuid
                          ),
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={12} lg={12}>
                      <Form>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="company_name"
                            placeholder="Company Name"
                            value={
                              templateDynamicValues[template.custom_email_uuid]
                                ?.company_name || ""
                            }
                            onChange={(e) =>
                              handleDynamicValueChange(
                                template.custom_email_uuid,
                                e
                              )
                            }
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="job_role"
                            placeholder="Job Role"
                            value={
                              templateDynamicValues[template.custom_email_uuid]
                                ?.job_role || ""
                            }
                            onChange={(e) =>
                              handleDynamicValueChange(
                                template.custom_email_uuid,
                                e
                              )
                            }
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="hiring_person_name"
                            placeholder="Hiring Person Name"
                            value={
                              templateDynamicValues[template.custom_email_uuid]
                                ?.hiring_person_name || ""
                            }
                            onChange={(e) =>
                              handleDynamicValueChange(
                                template.custom_email_uuid,
                                e
                              )
                            }
                          />
                        </Form.Group>
                      </Form>
                    </Col>
                    <Col
                      md={8}
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <button
                        className="custom-template-update mr-2"
                        onClick={() => handleShowModal("update", template)}
                      >
                        Update
                      </button>
                      <button
                        className="custom-template-delete"
                        onClick={() =>
                          handleDeleteTemplate(template.custom_email_uuid)
                        }
                      >
                        Delete
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {/* Modal for creating/updating a custom template */}
      <TemplateModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        modalMode={modalMode}
        currentTemplate={currentTemplate}
        handleInputChange={handleInputChange}
        handleDescriptionChange={handleDescriptionChange}
        handleSaveTemplate={handleSaveTemplate}
      />
    </div>
  );
};

export default CustomTemplates;
