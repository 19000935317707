/*global chrome*/
import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import { useSpring, animated, useTransition } from "@react-spring/web";
import {
  Card,
  Dropdown,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "./ReferralFilters.css";
import "./XYZ1.css";
import { v1 as uuidv1 } from "uuid";
import ChatGPTDropdown from "./XYZDropdown";
import { useHistory } from "react-router-dom";
import DynamicSteps from "./dynamicsteps";
import { useStateStore } from "../../store";
import axios from "axios";
import ReferralList from "./ReferralList";
import CustomAlert from "../modal";
import ReferralModal from "./ReferralModal";
import InfoModal from "./InfoModal";
import SendEmailSuccess from "./SendEmailSuccess";
import ReferralAutomationBlock from "./ReferralAutomationBlock";
import companiesMain from "./companies1.json";
import { GoogleOAuthProvider } from "@react-oauth/google";
import InitBlock from "./InitBlock";
import UploadResume from "./UploadResume";
import { Hotjar } from "../../index";

const AnimatedSearchBar = () => {
  const {
    setReferralPrompts,
    userData,
    token,
    referralPrompts,
    currentGoalId,
    setCurrentGoalId,
    backClick,
    companies,
    setCompanies,
    searchValue,
    setSearchValue,
    globalLogout,
    showMainReferralModal,
    setShowMainReferralModal,
    showMainBlock,
    emailAutomationReferral,
    yourExtensionId,
    scriptInjectedGlobal,
    setScriptInjectedGlobal,
    setExtensionPresent,
    setGlobalTabId,
    setStopClick,
    setShowEmailSentSuccess,
    showEmailSentSuccess,
    selectedCompanies,
    clearAll,
    setEmailAutomationReferral,
    setRevelantReferralJobTitles,
    relevantReferralJobTitles,
    setSendEmail,
    setGlobalStopReferral,
    setShowMainBlock,
    setEmployees: setEmployeesMain,
    setButtonClick,
    setMainReferralPause,
    extensionPresent,
    setInitialString,
    setEmailData,
    setEmailList,
    emailList,
    setMainCompanySet,
    setMainModalTitle,
    setEmployeeQuery,
    setReferralResumeData,
    showEmailPermissionModal,
    setShowEmailPermissionModal,
    setCustomTemplates,
    setReferralInitData,
    resumeReferralData,
    userPlanDetails,
    setUserPlanDetails,
    resumeValidation,
    setResumeReferralData,
    setResumeValidation,
  } = useStateStore();
  const CLIENT_ID =
    "715818145189-7geg3j3td9u21nc9qe8eujb2l4dcbt88.apps.googleusercontent.com";
  const [initialDataLoader, setInitialDataLoader] = useState(false);
  const [infoModalType, setInfoModalType] = useState("success");
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const [examplePromptIndex, setExamplePromptIndex] = useState(0);
  const [loadingFinish, setLoadingFinish] = useState(false);
  const [showPromptsLoading, setShowPromptsLoading] = useState(false);
  const history = useHistory();
  const [animationFinish, setAnimationFinish] = useState(0);
  const examplePrompts = [
    {
      id: "example_prompt_1",
      text: "Find and apply 30 companies with a salary above 20 LPA in India. Filter for companies known for their strong company culture, employee benefits, and inclusive environment.",
    },
    {
      id: "example_prompt_2",
      text: "Find and apply 25 companies in new york with salary more than 50k $ in ecommerce domain.",
    },
    {
      id: "example_prompt_3",
      text: "Find and apply 30 fintech companies, mostly remote or in US, with a salary greater than 100k $. Ensure the companies have an annual revenue of over $10 million or have raised significant funding (Series A or later).",
    },
  ];
  const [dropdownOptions, setDropDownOptions] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [topPosition, setTopPosition] = useState(50);
  const ref = useRef(null);
  const containerRef = useRef(null);
  const [
    pauseGlobalReferralStopPlayButton,
    setPauseGlobalReferralStopPlayButton,
  ] = useState(false);
  const [stepSelection, setStepSelection] = useState(1);
  const [mainAutomationStep, setMainAutomationStep] = useState("");
  const [companiesResponse, setCompaniesResponse] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [showAutomationCompaniesPopup, setShowAutomationCompaniesPopup] =
    useState(false);
  const [promptIndexSelect, setPromptIndexSelected] = useState(-1);
  let mainEditEmailInterval = null;
  let mainPausedInterval = null;
  let mainInterval = null;
  let mainEmailSettingsInterval = null;
  let mainEmailSettingsTimeout = null;
  let mainTimeout = null;
  let mainTimeoutWaitForPeopleList = null;

  const closeEmailSuccessModal = () => {
    setShowEmailSentSuccess(false);
  };

  const checkifpaused = async () => {
    if (!useStateStore.getState().globalStopReferral) {
      return;
    }

    await new Promise((resolve) => {
      mainPausedInterval = setInterval(() => {
        console.log(
          "checkifpaused interval running:",
          useStateStore.getState().globalStopReferral
        );

        if (!useStateStore.getState().globalStopReferral) {
          console.log("Clearing interval and resolving checkifpaused");
          clearInterval(mainPausedInterval);
          resolve();
        }
      }, 200);
    });
  };

  function smoothScrollToTop() {
    let start = null;
    const initialScrollTop = window.scrollY;

    function easeOutCubic(t) {
      return 1 - Math.pow(1 - t, 3);
    }

    function step(timestamp) {
      if (start === null) start = timestamp;
      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / 1000, 1);

      const easedProgress = easeOutCubic(progress);
      const newScrollTop = initialScrollTop * (1 - easedProgress);

      window.scrollTo(0, newScrollTop);

      if (newScrollTop > 0) {
        window.requestAnimationFrame(step);
      }
    }

    window.requestAnimationFrame(step);
  }
  const checkIfStopped = async (mainparam = false) => {
    await checkifpaused();

    if (useStateStore.getState().stopClick || mainparam) {
      closeFn();
      clearAll();
      setCompanies([]);
      setAnimationFinish(0);
      setLoadingFinish(false);
      setExpanded(false);
      setSearchValue("");
      setPauseGlobalReferralStopPlayButton(false);
      setShowAutomationCompaniesPopup(false);
      setCompaniesResponse("");
      setMainAutomationStep("");
      setShowLoader(true);
      setCompanies([]);
      if (mainPausedInterval) {
        clearInterval(mainPausedInterval);
      }

      if (mainEditEmailInterval) {
        clearInterval(mainEditEmailInterval);
      }

      if (mainEmailSettingsInterval) {
        clearInterval(mainEmailSettingsInterval);
      }

      if (mainEmailSettingsTimeout) {
        clearInterval(mainEmailSettingsTimeout);
      }

      if (mainInterval) {
        clearInterval(mainInterval);
      }

      if (mainTimeout) {
        clearTimeout(mainTimeout);
      }

      if (mainTimeoutWaitForPeopleList) {
        clearTimeout(mainTimeoutWaitForPeopleList);
      }

      setMainAutomationStep("Automation Completed");
      setEmailAutomationReferral(false);
      smoothScrollToTop();
      setPromptIndexSelected(-1);
      setStepSelection(1);
      throw new Error("Automation stopped");
    }
  };

  useLayoutEffect(() => {
    const handleResize = () => {
      if (ref.current && containerRef.current) {
        const rect = ref.current.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();
        const topRelativeToContainer = rect.top - containerRect.top;
        const topInPercentage =
          (topRelativeToContainer / containerRect.height) * 100;
        setTopPosition(topInPercentage);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [expanded]);

  const ifPlanExist = () => {
    const user_plan_details = useStateStore.getState().userPlanDetails || {};
    if (
      Object.keys(user_plan_details).length === 0 ||
      (user_plan_details.currentPlan &&
        !user_plan_details.currentPlan.planStarted)
    ) {
      return 0;
    } else {
      return 1;
    }
  };

  const options = [
    {
      name: "Pricing",
      route: "/dashboard/pricing",
      fn: () => {},
    },
    // ifPlanExist()
    //   ? {
    //       name: "Email Templates",
    //       route: "/dashboard/custom-templates",
    //       fn: () => {},
    //     }
    //   : {},
    {
      name: "Email Templates",
      route: "/dashboard/custom-templates",
      fn: () => {},
    },
    {
      name: "Logout",
      fn: globalLogout,
    },
  ];

  const { top, width } = useSpring({
    top: expanded ? 5 : topPosition,
    width: expanded ? 80 : 50,
    config: {
      mass: 3,
      tension: 45,
      friction: 35,
      duration: 1000,
    },
    onRest: () => {
      if (expanded) {
        setAnimationFinish(1);
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
        if (expanded && textareaRef.current) {
          textareaRef.current.focus();
          const length = textareaRef.current.value.length;
          textareaRef.current.setSelectionRange(length, length);
        }
      }
    },
  });

  const intervalRef = useRef(null);

  useEffect(() => {
    if (expanded) {
      intervalRef.current = setInterval(() => {
        handleInput();
      }, 100);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [expanded]);

  const contentAnimation = useSpring({
    from: {
      opacity: 0,
      height: "0px",
    },
    to: {
      opacity: expanded ? 1 : 0,
      height: expanded ? "auto" : "0px",
    },
    delay: expanded ? 800 : 0,
    config: {
      mass: 2,
      tension: 45,
      friction: 30,
      duration: 1000,
    },
  });

  const handleSearchFromExample = async (index) => {
    getCompanies({
      prompt_text: examplePrompts[index].text,
      prompt_second: "",
      prompt_final: examplePrompts[index].text,
    });
    setExpanded(true);
  };

  const handleSearchTopbar = async (e) => {
    e.preventDefault();

    if (searchValue != "" && !(previousSearchText == searchValue)) {
      setLoadingFinish(false);
      setCompanies([]);
      const prompt_value = referralPrompts[selectedIndex];
      const response = await axios.post(
        `https://backend.lazyapply.com/referral/prompts`,
        {
          v2Id: userData?.v2Id || "",
          prompt_text: searchValue,
          prompt_second: "",
          goal_text: prompt_value.goal_text,
          id: uuidv1(),
          main: false,
          goal_id: prompt_value.goal_id,
          key_values: {
            referral_job_title: prompt_value.referral_job_title,
            employeeQuery: prompt_value.employeeQuery,
            salary: prompt_value.salary,
            country: prompt_value.country,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPromptData(response.data.prompt);
      getCompanies(response.data.prompt);
      setExpanded(true);
    }
  };

  const [promptData, setPromptData] = useState({});
  const handleSearch = async (e) => {
    e.preventDefault();

    if (searchValue != "" && !(previousSearchText == searchValue)) {
      const prompt_value = referralPrompts[selectedIndex];
      const response = await axios.post(
        `https://backend.lazyapply.com/referral/prompts`,
        {
          v2Id: userData?.v2Id || "",
          prompt_text: searchValue,
          prompt_second: "",
          goal_text: prompt_value.goal_text,
          id: uuidv1(),
          goal_id: prompt_value.goal_id,
          key_values: {
            referral_job_title: prompt_value.referral_job_title,
            employeeQuery: prompt_value.employeeQuery,
            salary: prompt_value.salary,
            country: prompt_value.country,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPromptData(response.data.prompt);
      getCompanies(response.data.prompt);
      setExpanded(true);
    }
  };

  const transitions = useTransition(!expanded, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 1000 },
  });

  const [isFocused, setIsFocused] = useState(false);
  const textareaRef = useRef(null);
  const [height, setHeight] = useState("30px");

  const maxHeight = 100;

  const handleInput = () => {
    if (textareaRef.current) {
      const minHeight = 30;
      textareaRef.current.style.height = `${minHeight}px`;
      const newHeight = textareaRef.current.scrollHeight;

      if (newHeight <= maxHeight) {
        textareaRef.current.style.height = `${newHeight}px`;
        textareaRef.current.style.overflowY = "hidden";
      } else {
        textareaRef.current.style.height = `${maxHeight}px`;
        textareaRef.current.style.overflowY = "auto";
      }
    }
  };

  const tooltip = <Tooltip id="tooltip">Try This Prompt</Tooltip>;
  const tooltip1 = <Tooltip id="tooltip">Message is empty</Tooltip>;

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "30px";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [expanded]);

  // useEffect(() => {
  //   setShowPromptsLoading(true);
  //   if (Object.keys(userData).length > 0) {
  //     axios
  //       .get(
  //         `https://backend.lazyapply.com/referral//${
  //           userData?.v2Id || ""
  //         }`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         console.log("response_resume", response);
  //         setResumeReferralData(response.data.resume);
  //         setResumeValidation(response.data.resume?.validation || 0);
  //       })
  //       .catch((err) => {
  //         console.log("error_resume", err);
  //       });

  //     axios
  //       .get(
  //         `https://backend.lazyapply.com/referral/prompts?v2Id=${
  //           userData?.v2Id || ""
  //         }`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         let prompts = response.data.prompts;
  //         let mainPrompts = prompts.map((prompt) => {
  //           return {
  //             employees: 2,
  //             goal_id: prompt.goal_id,
  //             goal_text: prompt.goal_text,
  //             id: prompt.prompt_id,
  //             prompt_second: prompt.prompt_second,
  //             prompt_final: prompt?.prompt_final || "",
  //             prompt: prompt.prompt_text,
  //             employeeQuery: prompt.key_values["employeeQuery"],
  //             referral_job_title: prompt.key_values["referral_job_title"],
  //             salary: prompt.key_values["salary"],
  //             country: prompt.key_values["country"],
  //           };
  //         });
  //         setReferralPrompts(mainPrompts);
  //         setDropDownOptions(mainPrompts);
  //         setShowPromptsLoading(false);
  //         if (mainPrompts.length == 0) {
  //           history.push("/dashboard/goal");
  //         }
  //       })
  //       .catch((err) => {
  //         setReferralPrompts([]);
  //         setShowPromptsLoading(false);
  //       });
  //   }
  // }, [userData]);

  const [previousSearchText, setPreviousSearchText] = useState("");
  const getCompanies = async (prompt) => {
    setPreviousSearchText(prompt.prompt_text);
    const response = await axios.post(
      `https://backend.lazyapply.com/referral/getCompaniesWithPrompt`,
      {
        prompt: prompt.prompt_text,
        prompt_second: prompt.prompt_second,
        prompt_final: prompt.prompt_final,
      }
    );
    console.log("prompt_final", prompt, response);
    setCompanies(response?.data?.companies || []);
    // setCompanies(companiesMain.companies);
  };

  useEffect(() => {
    console.log(
      "referralprompts",
      referralPrompts,
      currentGoalId,
      selectedIndex
    );
    if (currentGoalId != -1 && referralPrompts.length > 0) {
      const index = referralPrompts.findIndex(
        (item) => item.goal_id === currentGoalId
      );
      setSelectedIndex(index);
    } else {
      if (referralPrompts.length > 0) {
        setCurrentGoalId(referralPrompts[0].goal_id);
      }
    }
  }, [currentGoalId, referralPrompts]);

  useEffect(() => {
    if (backClick && companies.length > 0) {
      setAnimationFinish(1);
      setExpanded(true);
      setLoadingFinish(true);
    }
  }, [backClick]);

  const goback = () => {
    setCompanies([]);
    setAnimationFinish(0);
    setLoadingFinish(false);
    setExpanded(false);
    setSearchValue("");
  };

  function getInitials(name) {
    let initials = "";
    const words = name.split(" ");

    for (let i = 0; i < words.length; i++) {
      if (words[i].length > 0) {
        initials += words[i][0].toUpperCase();
      }
    }

    return initials;
  }

  const closeFn = () => {
    setShowMainReferralModal(false);
  };

  const delay = (ms) => {
    return new Promise((resolve, reject) => {
      let start = Date.now();

      const checkDelay = async () => {
        await checkIfStopped();

        if (Date.now() - start >= ms) {
          resolve();
        } else {
          setTimeout(checkDelay, 100);
        }
      };

      checkDelay();
    });
  };

  const getRevelantJobTitles = async (
    prompt,
    jobTitle,
    prompt_second,
    prompt_final
  ) => {
    try {
      const response = await axios.post(
        `https://backend.lazyapply.com/referral/getJobTitlesForReferral`,
        {
          prompt: prompt,
          prompt_second: prompt_second,
          jobTitle: jobTitle,
          prompt_final: prompt_final,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRevelantReferralJobTitles(response.data.referral_job_titles);
      return response.data.referral_job_titles;
    } catch (err) {
      return relevantReferralJobTitles;
    }
  };

  useEffect(() => {
    // fetchTemplates()
    const usermain = JSON.parse(window.localStorage.getItem("user") || "{}");
    const tokenmain = window.localStorage.getItem("token");
    if (
      tokenmain != "" &&
      usermain != undefined &&
      Object.keys(usermain).length > 0
    ) {
      if (typeof chrome !== "undefined" && chrome && chrome.runtime)
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            dashboard: true,
            saveValues: {
              token: tokenmain,
              user: usermain,
            },
            injected: scriptInjectedGlobal,
            global: true,
            message: "loginToken",
          },
          (response) => {
            var lastError = chrome.runtime?.lastError;
            if (lastError) {
              console.log("some error");
              setScriptInjectedGlobal(false);
              setExtensionPresent(false);
            } else {
              console.log("response of initial request", response);
              setScriptInjectedGlobal(true);
              setGlobalTabId(response.globalTabId);
            }
          }
        );
    }
  }, []);

  let chromeExtensionPresent = async () => {
    let extensionPresentMain = 0;
    if (typeof chrome !== "undefined" && chrome && chrome.runtime) {
      extensionPresentMain = await new Promise((resolve, reject) => {
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            lazyapply: "lazyapply",
            message: "check",
            injected: scriptInjectedGlobal,
          },
          function (response) {
            var lastError = chrome.runtime.lastError;
            if (lastError) {
              console.log("error", lastError.message);
              resolve(0);
            }
            if ("status" in response && response.status == "installed") {
              setScriptInjectedGlobal(true);
              setExtensionPresent(true);
              resolve(1);
            }
          }
        );
      });
      return extensionPresentMain;
    } else {
      extensionPresentMain = 0;
      return extensionPresentMain;
    }
  };

  const PauseFunction = async () => {
    await new Promise((resolve) => {
      if (extensionPresent) {
        resolve();
      } else {
        let extensionCheckInterval = setInterval(() => {
          if (useStateStore.getState().installExtensionDuringAutomation) {
            setMainReferralPause({
              status: false,
              text: "",
            });
            clearInterval(extensionCheckInterval);
            resolve();
          } else {
            chromeExtensionPresent();
            setMainReferralPause({
              status: true,
              text: "Install Lazyapply Extension To Continue Automation",
              text1: "If Already Installed Click Continue Automation",
            });
          }
        }, 500);
      }
    });
  };

  const getEmail = async (employeeSelected) => {
    if (employeeSelected.linkedinUrl in emailList) {
      setEmailData({
        email: emailList[employeeSelected.linkedinUrl],
      });
      return {
        email: emailList[employeeSelected.linkedinUrl],
      };
    } else {
      const response = await axios.post(
        `https://backend.lazyapply.com/referral/getEmailMain`,
        {
          employee: employeeSelected,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setEmailData(response.data.emailData);
      setEmailList({
        linkedin: employeeSelected.linkedinUrl,
        email: response.data.emailData.email,
      });
      return response.data.emailData;
    }
  };

  const getInitData = async () => {
    setInitialDataLoader(true);
    const initDataResponse = await axios.post(
      "https://backend.lazyapply.com/referral/getReferralData",
      {
        v2Id: userData?.v2Id || "",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("init", initDataResponse);
    setInitialDataLoader(false);

    setReferralInitData(initDataResponse.data);
    return 1;
  };

  const checkIfConnected = () => {
    return useStateStore.getState().referralInitData?.emailConnected &&
      useStateStore.getState().referralInitData.emailConnected
      ? true
      : false;
  };

  const EmailPermission = async () => {
    await new Promise((resolve) => {
      if (checkIfConnected()) {
        resolve();
      } else {
        let extensionCheckInterval = setInterval(() => {
          if (!checkIfConnected()) {
            setMainReferralPause({
              status: true,
              text: "Give Permission To Connect Email",
            });
          } else {
            clearInterval(extensionCheckInterval);
            setMainReferralPause({
              status: false,
              text: "",
            });
            resolve();
          }
        }, 500);
      }
    });
  };

  const checkCondition = () => {
    const { referral } = useStateStore.getState().referralInitData;

    const isReferralPresent = referral && Object.keys(referral).length > 0;

    const hasEmailTemplateOption =
      typeof referral?.emailTemplateOption === "number" &&
      referral.emailTemplateOption !== -1;

    console.log("condition", isReferralPresent && hasEmailTemplateOption);
    return isReferralPresent && hasEmailTemplateOption;
  };

  const checkConditionAndModify = () => {
    const { referral } = useStateStore.getState().referralInitData;

    const isReferralPresent = referral && Object.keys(referral).length > 0;

    const hasEmailTemplateOption =
      typeof referral?.emailTemplateOption === "number" &&
      referral.emailTemplateOption !== -1;

    const mainCondition = isReferralPresent && hasEmailTemplateOption;
    if (mainCondition) {
      return true;
    } else {
      const prompts = useStateStore.getState().referralPrompts;
      const selected_goal_id = useStateStore.getState().currentGoalId;
      const promptDataMain = prompts.filter(
        (item) => item.goal_id === selected_goal_id
      )[0];
      setReferralInitData({
        ...useStateStore.getState().referralInitData,
        referral: {
          ...referral,
          emailTemplateOption: 2,
          customTemplateId: "",
          resumeId: useStateStore.getState().resumeReferralData.resumeId,
          referralTitle: promptDataMain.referral_job_title,
        },
      });
      return true;
    }
  };

  const PauseFunctionIfResumeNotSelectedOrCompleted = async () => {
    return new Promise((resolve) => {
      const extensionCheckInterval = setInterval(() => {
        if (
          checkCondition() &&
          useStateStore.getState().profileSelectedDuringAutomation
        ) {
          setMainReferralPause({
            status: false,
            text: "",
          });
          clearInterval(extensionCheckInterval);
          resolve();
        } else {
          setMainReferralPause({
            status: true,
            text: "Please Fill The Email Setting Options",
            text1: "If Already Completed, Click 'Save Your Choice'",
          });
          console.log("do nothing");
        }
      }, 500);
    });
  };

  const PauseBeforeWritingEmailToChooseTemplateOrAiGeneratedEmail =
    async () => {
      await new Promise(async (resolve) => {
        await checkIfStopped();
        mainEmailSettingsTimeout = setTimeout(async () => {
          await checkIfStopped();
          if (useStateStore.getState().editEmailSettings) {
            mainEmailSettingsInterval = setInterval(async () => {
              await checkIfStopped();
              if (!useStateStore.getState().editEmailSettings) {
                clearInterval(mainEmailSettingsInterval);
                resolve();
              } else {
              }
            }, 100);
          } else {
            resolve();
          }
        }, 5000);
      });
    };

  const checkIfEmailPresent = () => {
    const employeeSelected = useStateStore.getState().employeeSelected;
    const emailList = useStateStore.getState().emailList;
    let email = "";
    if (employeeSelected.linkedinUrl in emailList) {
      email = emailList[employeeSelected.linkedinUrl];
    }

    console.log("emaillist", emailList, employeeSelected, email);
    return email;
  };

  async function calculateTotalTime() {
    return await new Promise((resolve) => {
      mainInterval = setInterval(async () => {
        await checkIfStopped();
        const emailDataString = useStateStore.getState().initialString;
        const words = emailDataString.split(" ");
        if (words.length > 1) {
          clearInterval(mainInterval);
          clearTimeout(mainTimeout);
          const intervalDelay = 100;
          const totalTime = words.length * intervalDelay;
          resolve(totalTime);
        }
      }, 100);

      mainTimeout = setTimeout(async () => {
        await checkIfStopped();
        const email = checkIfEmailPresent();
        if (email == "") {
          clearTimeout(mainTimeout);
          clearInterval(mainInterval);
          resolve(-1);
        }
      }, 3000);
    });
  }

  const fetchUserResumeData = async () => {
    const v2Id = userData?.v2Id || "";
    try {
      const response = await axios.get(
        `https://backend.lazyapply.com/v2/resumesV3/checkResumesValidation/${v2Id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setReferralResumeData(response.data);
    } catch (err) {
      setReferralResumeData({});
    }
    // const response = await axios.get(
    //   `https://backend.lazyapply.com/v2/resumesV3/completed/${userData.planDetails.v2Id}`,
    //   {
    //     headers: { Authorization: `Bearer ${token}` },
    //   }
    // );
    // setReferralResumeData(response.data);
  };

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(
        "https://backend.lazyapply.com/referral/fetchCustomTemplates",
        {
          params: { v2Id: userData?.v2Id || "" },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCustomTemplates(response.data.templates);
    } catch (error) {
      console.error("Error fetching templates", error);
    }
  };

  const sendEmail = async () => {
    const initialString = useStateStore.getState().initialString;
    const mainCompanySet = useStateStore.getState().mainCompanySet;
    const employee_selected = useStateStore.getState().employeeSelected;
    const email_data = useStateStore.getState().emailData;
    const customTemplates = useStateStore.getState().customTemplates;

    if (email_data?.email && email_data.email != "") {
      const prompts = useStateStore.getState().referralPrompts;
      const selected_goal_id = useStateStore.getState().currentGoalId;
      const promptData = prompts.filter(
        (item) => item.goal_id === selected_goal_id
      )[0];

      const referral_init_data = useStateStore.getState().referralInitData;
      let emailTitle = `Referral For ${promptData.referral_job_title} Position`;
      if (
        referral_init_data.referral?.emailTemplateOption &&
        referral_init_data.referral?.emailTemplateOption == 1
      ) {
        emailTitle =
          customTemplates.filter(
            (obj) =>
              obj.custom_email_uuid ==
              referral_init_data.referral?.customTemplateId
          )[0]?.email_title ||
          `Referral For ${promptData.referral_job_title} Position`;
      }

      const sendResponse = await axios.post(
        "https://backend.lazyapply.com/referral/sendEmailV2",
        {
          employee: employee_selected,
          v2Id: userData?.v2Id || "",
          emailData: email_data,
          resume: useStateStore.getState().resumeReferralData,
          referraltitle: promptData.referral_job_title,
          companyDetails: mainCompanySet,
          emailDescription: initialString,
          emailTitle: emailTitle,
          userData: userData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response send email", sendResponse);
      setShowEmailSentSuccess(true);
    } else {
    }
  };

  const getUserPlanDetails = async () => {
    const v2Id = userData?.v2Id || "";
    try {
      const planDetailsResponse = await axios.get(
        `https://backend.lazyapply.com/referral/user-plan-analytics/${v2Id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("planDetailsResponse", planDetailsResponse?.data);
      if (planDetailsResponse?.data && planDetailsResponse.data) {
        setUserPlanDetails(planDetailsResponse.data);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const getResumeMain = async () => {
    try {
      const response = await axios.get(
        `https://backend.lazyapply.com/referral/getResume/${
          userData?.v2Id || ""
        }/${currentGoalId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setResumeReferralData(response.data.resume);
      setResumeValidation(response.data.resume?.validation || 0);
    } catch (err) {
      console.log("error_resume", err);
    }
  };

  const findEmployees = async (domain, jobtitle) => {
    try {
      const employeesResponse = await axios.post(
        "https://backend.lazyapply.com/referral/findEmployees",
        {
          domain: domain,
          jobtitle: jobtitle,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return employeesResponse.data;
    } catch (error) {
      console.error("Error fetching templates", error);
      return [];
    }
  };

  const findCompanyWithName = async (companyName, domain) => {
    try {
      const companyResponse = await axios.post(
        "https://backend.lazyapply.com/referral/findCompaniesWithName",
        {
          companyQuery: companyName,
          domain: domain,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return companyResponse.data.length > 0 ? companyResponse.data[0] : {};
    } catch (error) {
      console.error("Error fetching templates", error);
      return {};
    }
  };

  const encodeValue = (value) => btoa(value.toString());
  const decodeValue = (encoded) => parseInt(atob(encoded), 10);

  const updateDemoTaken = () => {
    const demoKey = "sys_prefetch_load";
    const encodedDemo = window.localStorage.getItem(demoKey);

    let demoTaken = encodedDemo ? decodeValue(encodedDemo) : 0;

    if (demoTaken < 2) {
      demoTaken += 1;
    }

    window.localStorage.setItem(demoKey, encodeValue(demoTaken));
  };

  const isDemoTakenLessThanTwo = () => {
    const demoKey = "sys_prefetch_load";
    const encodedDemo = window.localStorage.getItem(demoKey);

    const demoTaken = encodedDemo ? decodeValue(encodedDemo) : 0;

    return demoTaken < 2;
  };

  async function performTaskWithoutPlan() {
    try {
      let stepAhead = 1;
      setStopClick(false);
      setSendEmail(false);
      setEmailAutomationReferral(true);
      setMainAutomationStep("Starting Automation");
      await delay(1000);
      setMainAutomationStep("Getting profile details..");
      if (!resumeReferralData || Object.keys(resumeReferralData).length == 0) {
        await delay(1500);
        setMainAutomationStep("You don't have a profile");
        await delay(1000);
        setEmailAutomationReferral(false);
        setShowCreateResumeModal(true);
        setResumeValidation(0);
        stepAhead = 0;
      } else if (
        Object.keys(resumeReferralData).length > 0 &&
        !resumeReferralData.validation
      ) {
        await delay(1500);
        setMainAutomationStep("Profile details not completed");
        await delay(1000);
        setEmailAutomationReferral(false);
        stepAhead = 0;
        setResumeValidation(1);
        setShowCreateResumeModal(true);
      }

      setMainAutomationStep("Profile details found!");

      if (stepAhead) {
        setStopClick(false);
        setSendEmail(false);
        setEmailAutomationReferral(true);

        const initResponse = getInitData();

        const titlesPromise = getRevelantJobTitles(
          referralPrompts[selectedIndex].prompt_text,
          referralPrompts[selectedIndex].referral_job_title,
          referralPrompts[selectedIndex].prompt_second,
          referralPrompts[selectedIndex].prompt_final
        );

        let givenCompaniesToApply = companies.length;
        await delay(1000);
        setMainAutomationStep(`${companies.length} Companies Found..`);

        let firstIndex = useStateStore.getState().selectedCompanies[0];
        let companyResult = findCompanyWithName(
          companies[firstIndex].name,
          companies[firstIndex].domain
        );
        await checkIfStopped();
        setMainAutomationStep(
          `${
            useStateStore.getState().selectedCompanies.length
          } Companies Selected To Apply..`
        );

        const titles = ["human resource", "recruiter"];

        await checkIfStopped();
        console.log(
          "selectedCompanies",
          useStateStore.getState().selectedCompanies
        );
        const allSelectedIndexes = useStateStore.getState().selectedCompanies;
        console.log("allselected indexes", allSelectedIndexes);
        let oneemailwritten = 0;
        for (let index = 0; index < givenCompaniesToApply; index++) {
          if (!allSelectedIndexes.includes(index)) {
            continue;
          }
          if (index != 0 && !(allSelectedIndexes[0] == index)) {
            setMainAutomationStep(`Going To Next Company`);
            companyResult = findCompanyWithName(
              companies[index].name,
              companies[index].domain
            );
          }

          const companyResponseMain = await companyResult;
          console.log("companyResponse", companyResponseMain.domain);
          let employeesResult = findEmployees(
            companyResponseMain.domain,
            titles[0]
          );
          setEmployeesMain([]);
          setSendEmail(false);
          setButtonClick(false);
          closeFn();
          await delay(2000);
          await initResponse;
          await checkIfStopped();
          let selectedIndex = index;
          const company = companies[selectedIndex];

          let alldomains = document.querySelectorAll(
            ".main-referral-container .main-domain"
          );
          let alldomainsmain = Array.from(alldomains).map((x) =>
            x.innerText.trim()
          );

          const domainIndex = alldomainsmain
            .map((domain) => domain.toLowerCase())
            .indexOf(company.domain.toLowerCase());

          console.log("domainindex", domainIndex);
          if (domainIndex === -1) {
            console.error("Domain not found.");
            continue;
          }

          setMainAutomationStep(
            `Opening Company ${alldomainsmain[domainIndex]}`
          );

          const mainDiv = alldomains[domainIndex];
          if (
            !mainDiv ||
            !mainDiv.parentNode ||
            !mainDiv.parentNode.parentNode
          ) {
            console.error("Main div or parent not found.");
            continue;
          }

          console.log("maindiv", mainDiv);

          mainDiv.parentNode.parentNode.scrollIntoView({
            behavior: "smooth",
          });

          setShowMainReferralModal(true);
          setMainModalTitle(`Apply To ${company.name}`);
          setMainCompanySet(company);

          await delay(2000);
          setPauseGlobalReferralStopPlayButton(false);

          setMainReferralPause({
            status: false,
            text: "",
          });

          let employee = titles;
          let peopleList = [];
          for (let index = 0; index < 1; index++) {
            console.log("first title", titles[index]);
            peopleList = [];
            setEmployeeQuery("");
            setEmployeesMain([]);
            setMainAutomationStep("Finding Employees");
            const input = document.querySelector("#input_jobtitles");
            console.log("input", input);
            await checkIfStopped();
            if (input) {
              setEmployeeQuery(employee[index]);
              await delay(500);
            }

            const waitForPeopleList = async () => {
              const timeout = 10000;
              const interval = 500;
              const startTime = Date.now();

              return new Promise((resolve, reject) => {
                const checkPeopleList = async () => {
                  await checkIfStopped();

                  const peopleList = document.querySelectorAll(
                    ".main-referral-container-peoplelist"
                  );

                  console.log("peoplelist", peopleList);

                  if (Array.from(peopleList).length > 0) {
                    clearTimeout(mainTimeoutWaitForPeopleList);
                    resolve(peopleList);
                  } else if (Date.now() - startTime >= timeout) {
                    clearTimeout(mainTimeoutWaitForPeopleList);
                    resolve([]);
                  } else {
                    mainTimeoutWaitForPeopleList = setTimeout(
                      checkPeopleList,
                      interval
                    );
                  }
                };

                checkPeopleList();
              });
            };

            let totalCount = 0;
            for (let index = 0; index < 10; index++) {
              setInitialString("");

              console.log("total count", index, totalCount);

              const backbutton = document.querySelector("#email-block-go-back");
              if (backbutton) backbutton.click();
              await delay(500);
              await checkIfStopped();

              if (totalCount == 1) {
                console.log("the end");
                setMainAutomationStep("Email Sent To All Employees");
                break;
              }

              await checkIfStopped();
              setMainAutomationStep("Matching Profiles");
              await delay(2000);

              setEmployeesMain(await employeesResult);
              peopleList = await waitForPeopleList();

              console.log("peoplelist", peopleList);
              if (peopleList.length < index + 1 || totalCount == 1) {
                console.log("the end");
                setMainAutomationStep("Email Sent To All Employees");
                break;
              }

              if (peopleList.length > 0)
                setMainAutomationStep("Employees Found");
              await delay(1000);
              setMainAutomationStep(`Selecting Employee No - ${index + 1}`);
              await delay(1000);

              const emailResponse = await getEmail(
                useStateStore.getState().employees[index]
              );
              console.log("emailResponse", emailResponse);
              await checkIfStopped();
              if (emailResponse.email == "") {
                setMainAutomationStep("Email Not Found");
                await delay(1000);
                setMainAutomationStep("Moving Ahead");

                continue;
              } else {
                totalCount++;
              }
              const buttonList = Array.from(peopleList).map((list) => {
                return list.querySelector(".main-referral-top-third button");
              });

              if (buttonList.length > 0) {
                buttonList[index]?.click();
                await EmailPermission();
                setMainReferralPause({
                  status: false,
                  text: "",
                });
                setMainAutomationStep("Writing Email For Employee");
              } else {
                setMainAutomationStep("Writing Email For Employee");
              }
              Hotjar.event("demo_taken");
              updateDemoTaken();
              await checkIfStopped();

              checkConditionAndModify();
              // if (checkCondition()) {
              //   setMainAutomationStep("Update Email Settings If Required.");
              //   await PauseBeforeWritingEmailToChooseTemplateOrAiGeneratedEmail();
              // } else {
              //   await PauseFunctionIfResumeNotSelectedOrCompleted();
              // }

              setMainAutomationStep("Writing Email For Employee");

              const time = await calculateTotalTime();
              console.log("time main", time);
              if (time == -1) {
                setMainAutomationStep("Email Not Found");
                continue;
              }

              await checkIfStopped();
              await delay(time);

              setMainAutomationStep("Waiting For Email Editing");
              await delay(5000);

              setMainAutomationStep("");
              oneemailwritten = 1;
              await new Promise((resolve) => {
                mainEditEmailInterval = setInterval(async () => {
                  await checkIfStopped();
                  const editEmailMain = useStateStore.getState().editEmail;
                  console.log("editemailmain", editEmailMain);
                  if (!editEmailMain) {
                    clearInterval(mainEditEmailInterval);
                    setMainAutomationStep("Sending Email");

                    // await sendEmail();
                    // setMainAutomationStep("Email Sent");
                    await delay(1000);
                    // closeEmailSuccessModal();
                    // setSendEmail(false);
                    resolve();
                  } else {
                    console.log("no resolve");
                  }
                }, 100);
              });
              // closeEmailSuccessModal();
              await delay(1000);
              setMainAutomationStep(
                "OOps! you don't have a plan, purchase a plan to continue"
              );
              await delay(2000);
              break;
            }
            break;
            setEmployeeQuery("");
            setEmployeesMain([]);
            await delay(100);
          }
          if (oneemailwritten) break;
          else {
            console.log("move ahead");
          }
        }
        // checkIfStopped(true);
        // setEmailAutomationReferral(false);
        // setTimeout(() => {
        //   setShowInfoModal(true);
        //   setInfoModalType("success");
        //   setTimeout(() => {
        //     setShowInfoModal(false);
        //   }, 2500);
        // }, 1000);
        closeFn();
        // setMainAutomationStep("Automation Completed");
        setEmailAutomationReferral(false);
        smoothScrollToTop();
        setPromptIndexSelected(-1);
        setTimeout(() => {
          setStepSelection(1);
        }, 1500);
        history.push("/dashboard/pricing");
      } else {
        console.log("okay");
      }
    } catch (error) {
      if (error.message === "Automation stopped") {
        await checkIfStopped();
        console.log("Automation process was stopped.");
      } else {
        console.error("An error occurred:", error);
        await checkIfStopped();
      }
    }
  }

  async function performTask2() {
    try {
      // console.log("resume", resumeReferralData, userPlanDetails);
      const user_plan_details_main =
        useStateStore.getState().userPlanDetails || {};
      if (
        Object.keys(user_plan_details_main).length === 0 ||
        (user_plan_details_main.currentPlan &&
          !user_plan_details_main.currentPlan.planStarted &&
          isDemoTakenLessThanTwo())
      ) {
        performTaskWithoutPlan();
      } else {
        let stepAhead = 1;
        setStopClick(false);
        setSendEmail(false);
        setEmailAutomationReferral(true);
        setMainAutomationStep("Starting Automation");
        await delay(1000);
        setMainAutomationStep("Getting profile details..");
        if (
          !resumeReferralData ||
          Object.keys(resumeReferralData).length == 0
        ) {
          await delay(1500);
          setMainAutomationStep("You don't have a profile");
          await delay(1000);
          setEmailAutomationReferral(false);
          setShowCreateResumeModal(true);
          setResumeValidation(0);
          stepAhead = 0;
        } else if (
          Object.keys(resumeReferralData).length > 0 &&
          !resumeReferralData.validation
        ) {
          await delay(1500);
          setMainAutomationStep("Profile details not completed");
          await delay(1000);
          setEmailAutomationReferral(false);
          stepAhead = 0;
          setResumeValidation(1);
          setShowCreateResumeModal(true);
        }

        setMainAutomationStep("Profile details found!");

        if (stepAhead) {
          setStopClick(false);
          setSendEmail(false);
          setEmailAutomationReferral(true);
          // setMainAutomationStep("Starting Automation");
          console.log(
            "useStateStore.getState().userPlanDetails",
            useStateStore.getState().userPlanDetails
          );
          const user_plan_details =
            useStateStore.getState().userPlanDetails || {};
          if (
            Object.keys(user_plan_details).length === 0 ||
            (user_plan_details.currentPlan &&
              !user_plan_details.currentPlan.planStarted)
          ) {
            await delay(2000);
            setMainAutomationStep(
              "OOps! you don't have a plan, purchase a plan to continue"
            );
            setTimeout(() => {
              setEmailAutomationReferral(false);
              history.push("/dashboard/pricing");
            }, 2500);
          } else {
            const initResponse = getInitData();
            // smoothScrollToTop();
            // setPauseGlobalReferralStopPlayButton(false);
            // setMainAutomationStep("Applying Company Filters");
            const titlesPromise = getRevelantJobTitles(
              referralPrompts[selectedIndex].prompt_text,
              referralPrompts[selectedIndex].referral_job_title,
              referralPrompts[selectedIndex].prompt_second,
              referralPrompts[selectedIndex].prompt_final
            );
            // const  companiesMain = companies
            // let timeTotal = calculateTimeForWordAnimation(companiesResponseMain, 50);
            // await delay(timeTotal + 5000);
            let givenCompaniesToApply = companies.length;
            await delay(1000);
            setMainAutomationStep(`${companies.length} Companies Found..`);
            // setShowAutomationCompaniesPopup(true);
            // smoothScrollToTop();
            // setPauseGlobalReferralStopPlayButton(true);
            // await delay(5000);
            // setPauseGlobalReferralStopPlayButton(false);
            // setShowAutomationCompaniesPopup(false);
            // setGlobalStopReferral(true);
            // setShowMainBlock(false);
            await checkIfStopped();
            setMainAutomationStep(
              `${
                useStateStore.getState().selectedCompanies.length
              } Companies Selected To Apply..`
            );
            // setMainAutomationStep("Getting Relevant Job Titles For Referrral");
            const titles = await titlesPromise;
            // setMainAutomationStep("Referral Titles Found");
            await checkIfStopped();
            console.log(
              "selectedCompanies",
              useStateStore.getState().selectedCompanies
            );
            const allSelectedIndexes =
              useStateStore.getState().selectedCompanies;
            console.log("allselected indexes", allSelectedIndexes);
            for (let index = 0; index < givenCompaniesToApply; index++) {
              if (!allSelectedIndexes.includes(index)) {
                continue;
              }
              if (index != 0 && !(allSelectedIndexes[0] == index))
                setMainAutomationStep(`Going To Next Company`);
              setEmployeesMain([]);
              setSendEmail(false);
              setButtonClick(false);
              closeFn();
              await delay(2000);
              await initResponse;
              await checkIfStopped();
              let selectedIndex = index;
              const company = companies[selectedIndex];

              let alldomains = document.querySelectorAll(
                ".main-referral-container .main-domain"
              );
              let alldomainsmain = Array.from(alldomains).map((x) =>
                x.innerText.trim()
              );

              const domainIndex = alldomainsmain
                .map((domain) => domain.toLowerCase())
                .indexOf(company.domain.toLowerCase());

              console.log("domainindex", domainIndex);
              if (domainIndex === -1) {
                console.error("Domain not found.");
                continue;
              }

              setMainAutomationStep(
                `Opening Company ${alldomainsmain[domainIndex]}`
              );
              // await delay(1000);

              const mainDiv = alldomains[domainIndex];
              if (
                !mainDiv ||
                !mainDiv.parentNode ||
                !mainDiv.parentNode.parentNode
              ) {
                console.error("Main div or parent not found.");
                continue;
              }

              console.log("maindiv", mainDiv);

              mainDiv.parentNode.parentNode.scrollIntoView({
                behavior: "smooth",
              });

              // const button = mainDiv.parentNode.parentNode.querySelector(
              //   "#main-ask-referral-button"
              // );

              // if (!button) {
              //   console.error("Button not found.");
              //   continue;
              // }

              // button.click();

              setShowMainReferralModal(true);
              setMainModalTitle(`Apply To ${company.name}`);
              setMainCompanySet(company);

              await delay(2000);
              setPauseGlobalReferralStopPlayButton(false);
              await PauseFunction();
              setMainReferralPause({
                status: false,
                text: "",
              });

              // console.log("button", button);

              let employee = titles;
              let peopleList = [];
              for (let index = 0; index < titles.length; index++) {
                console.log("first title", titles[index]);
                peopleList = [];
                setEmployeeQuery("");
                setEmployeesMain([]);
                setMainAutomationStep("Finding Employees");
                const input = document.querySelector("#input_jobtitles");
                console.log("input", input);
                await checkIfStopped();
                if (input) {
                  setEmployeeQuery(employee[index]);
                  await delay(500);
                  // input.parentNode.parentNode.querySelector("button").click();
                }

                const waitForPeopleList = async () => {
                  const timeout = 10000;
                  const interval = 500;
                  const startTime = Date.now();

                  return new Promise((resolve, reject) => {
                    const checkPeopleList = async () => {
                      await checkIfStopped();

                      const peopleList = document.querySelectorAll(
                        ".main-referral-container-peoplelist"
                      );

                      console.log("peoplelist", peopleList);

                      if (Array.from(peopleList).length > 0) {
                        clearTimeout(mainTimeoutWaitForPeopleList);
                        resolve(peopleList);
                      } else if (Date.now() - startTime >= timeout) {
                        clearTimeout(mainTimeoutWaitForPeopleList);
                        resolve([]);
                      } else {
                        mainTimeoutWaitForPeopleList = setTimeout(
                          checkPeopleList,
                          interval
                        );
                      }
                    };

                    checkPeopleList();
                  });
                };

                let totalCount = 0;
                for (let index = 0; index < 10; index++) {
                  setInitialString("");

                  console.log("total count", index, totalCount);

                  // if (index != 0) {
                  const backbutton = document.querySelector(
                    "#email-block-go-back"
                  );
                  if (backbutton) backbutton.click();
                  await delay(500);
                  await checkIfStopped();

                  if (totalCount == 1) {
                    console.log("the end");
                    setMainAutomationStep("Email Sent To All Employees");
                    break;
                  }

                  await checkIfStopped();
                  const button = document.querySelector(
                    "#main-find-employee-top"
                  );
                  console.log("button", button);
                  let linkedin_opened = 0;
                  if (button && peopleList.length == 0) {
                    setMainAutomationStep("Opening Linkedin");
                    linkedin_opened = 1;
                    await delay(2000);
                    button.click();
                  }
                  await delay(500);
                  // }

                  peopleList = await waitForPeopleList();
                  console.log("peoplelist", peopleList);
                  if (peopleList.length < index + 1 || totalCount == 1) {
                    console.log("the end");
                    setMainAutomationStep("Email Sent To All Employees");
                    break;
                  }

                  if (linkedin_opened == 1)
                    setMainAutomationStep("Employees Found");
                  await delay(500);
                  setMainAutomationStep(`Selecting Employee No - ${index + 1}`);
                  await delay(500);

                  const emailResponse = await getEmail(
                    useStateStore.getState().employees[index]
                  );
                  console.log("emailResponse", emailResponse);
                  await checkIfStopped();
                  if (emailResponse.email == "") {
                    setMainAutomationStep("Email Not Found");
                    await delay(1000);
                    setMainAutomationStep("Moving Ahead");

                    continue;
                  } else {
                    totalCount++;
                  }
                  const buttonList = Array.from(peopleList).map((list) => {
                    return list.querySelector(
                      ".main-referral-top-third button"
                    );
                  });

                  if (buttonList.length > 0) {
                    buttonList[index]?.click();
                    await EmailPermission();
                    setMainReferralPause({
                      status: false,
                      text: "",
                    });
                    setMainAutomationStep("Writing Email For Employee");
                  } else {
                    setMainAutomationStep("Writing Email For Employee");
                  }

                  await checkIfStopped();

                  if (checkCondition()) {
                    setMainAutomationStep("Update Email Settings If Required.");
                    await PauseBeforeWritingEmailToChooseTemplateOrAiGeneratedEmail();
                  } else {
                    await PauseFunctionIfResumeNotSelectedOrCompleted();
                  }

                  setMainAutomationStep("Writing Email For Employee");

                  const time = await calculateTotalTime();
                  console.log("time main", time);
                  if (time == -1) {
                    setMainAutomationStep("Email Not Found");
                    continue;
                  }

                  await checkIfStopped();
                  await delay(time);

                  setMainAutomationStep("Waiting For Email Editing");
                  await delay(5000);

                  setMainAutomationStep("");

                  await new Promise((resolve) => {
                    mainEditEmailInterval = setInterval(async () => {
                      await checkIfStopped();
                      const editEmailMain = useStateStore.getState().editEmail;
                      console.log("editemailmain", editEmailMain);
                      if (!editEmailMain) {
                        // const sendButton = document.querySelector(
                        //   "#referral-main-send-email"
                        // );
                        // console.log("sendbutton", sendButton);
                        // if (sendButton) {
                        clearInterval(mainEditEmailInterval);
                        setMainAutomationStep("Sending Email");
                        // sendButton.click();
                        await sendEmail();
                        setMainAutomationStep("Email Sent");
                        await delay(1000);
                        closeEmailSuccessModal();
                        setSendEmail(false);
                        // await delay(2000);
                        resolve();
                        // }
                      } else {
                        console.log("no resolve");
                      }
                    }, 100);
                  });
                  closeEmailSuccessModal();
                }
                setEmployeeQuery("");
                setEmployeesMain([]);
                await delay(100);
              }
            }
            setTimeout(() => {
              setShowInfoModal(true);
              setInfoModalType("success");
              setTimeout(() => {
                setShowInfoModal(false);
              }, 2500);
            }, 1000);
            closeFn();
            setMainAutomationStep("Automation Completed");
            setEmailAutomationReferral(false);
            smoothScrollToTop();
            setPromptIndexSelected(-1);
            setTimeout(() => {
              setStepSelection(1);
            }, 1500);
          }
        } else {
          console.log("okay");
        }
      }
    } catch (error) {
      if (error.message === "Automation stopped") {
        await checkIfStopped();
        console.log("Automation process was stopped.");
      } else {
        console.error("An error occurred:", error);
        await checkIfStopped();
      }
    }
  }

  const closePermissionModal = () => {
    setShowEmailPermissionModal(false);
  };

  const [showCreateResumeModal, setShowCreateResumeModal] = useState(false);

  function closeUploadResumeModal() {
    setShowCreateResumeModal(false);
  }

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!token || Object.keys(userData).length === 0) {
      setLoading(false);
      return;
    }

    const fetchResumeAndPrompts = Promise.all([
      axios
        .get(
          `https://backend.lazyapply.com/referral/getResume/${
            userData?.v2Id || ""
          }/${currentGoalId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setResumeReferralData(response.data.resume);
          setResumeValidation(response.data.resume?.validation || 0);
        })
        .catch((err) => console.log("error_resume", err)),

      axios
        .get(
          `https://backend.lazyapply.com/referral/prompts?v2Id=${
            userData?.v2Id || ""
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          let prompts = response.data.prompts.map((prompt) => ({
            employees: 2,
            goal_id: prompt.goal_id,
            goal_text: prompt.goal_text,
            id: prompt.prompt_id,
            prompt_second: prompt.prompt_second,
            prompt_final: prompt?.prompt_final || "",
            prompt: prompt.prompt_text,
            employeeQuery: prompt.key_values["employeeQuery"],
            referral_job_title: prompt.key_values["referral_job_title"],
            salary: prompt.key_values["salary"],
            country: prompt.key_values["country"],
          }));
          console.log("prompts", prompts);
          setReferralPrompts(prompts);
          setDropDownOptions(prompts);
          if (prompts.length === 0) {
            history.push("/dashboard/goal");
          }
        })
        .catch(() => setReferralPrompts([])),
    ]);

    const fetchTemplatesAndPlanDetails = async () => {
      try {
        const templatesResponse = await axios.get(
          "https://backend.lazyapply.com/referral/fetchCustomTemplates",
          {
            params: { v2Id: userData?.v2Id || "" },
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setCustomTemplates(templatesResponse.data.templates);
      } catch (error) {
        console.error("Error fetching templates", error);
      }

      try {
        const planDetailsResponse = await axios.get(
          `https://backend.lazyapply.com/referral/user-plan-analytics/${
            userData?.v2Id || ""
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setUserPlanDetails(planDetailsResponse.data);
      } catch (err) {
        console.error("Error fetching user plan details", err);
      }
    };

    setLoading(true);

    Promise.all([
      fetchResumeAndPrompts,
      fetchTemplatesAndPlanDetails(),
    ]).finally(() => setLoading(false));
  }, [userData, token, history, currentGoalId]);

  const FullPageSkeletonLoader = () => (
    <div className="skeleton-fullscreen">
      {/* Header Placeholder */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div className="skeleton-loader skeleton-header"></div>
        <div
          className="skeleton-loader skeleton-header"
          style={{ width: "80px" }}
        ></div>
      </div>

      {/* Main Prompt Text Placeholder */}
      <div
        className="skeleton-loader skeleton-text"
        style={{ marginTop: "250px" }}
      ></div>

      {/* Search Bar Placeholder */}
      <div className="skeleton-loader skeleton-search-bar"></div>

      {/* Prompt Examples Section Placeholder */}
      <div style={{ marginTop: "30px", width: "100%", textAlign: "center" }}>
        <div
          className="skeleton-loader skeleton-text"
          style={{
            width: "40%",
            marginBottom: "20px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            gap: "16px",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <div className="skeleton-loader skeleton-prompt-card"></div>
          <div className="skeleton-loader skeleton-prompt-card"></div>
          <div className="skeleton-loader skeleton-prompt-card"></div>
        </div>
      </div>
    </div>
  );

  if (loading)
    return (
      <>
        <FullPageSkeletonLoader />
      </>
    );

  return (
    <>
      {animationFinish && loadingFinish ? (
        <button className="goal-back-button" onClick={goback}>
          back
        </button>
      ) : (
        <></>
      )}
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      <div
        ref={containerRef}
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          minHeight: "600px",
          overflow: "auto",
          display: "flex",
          padding: "20px",
          flexDirection: "column",
          backgroundColor: "#fafafe",
        }}
      >
        <div
          className="main-referral-create-goal-container"
          style={{ height: "200px" }}
        >
          {dropdownOptions.length > 0 && selectedIndex != -1 && (
            <div className="main-referral-create-goal-dropdown">
              {transitions(
                (styles, item) =>
                  item && (
                    <animated.div style={styles} className="fadeDiv">
                      <ChatGPTDropdown
                        setSelectedIndex={setSelectedIndex}
                        dropdownOptions={dropdownOptions}
                        selectedOption={selectedIndex}
                      />
                    </animated.div>
                  )
              )}
            </div>
          )}

          <div className="main-referral-create-goal-button-div">
            {transitions(
              (styles, item) =>
                item && (
                  <animated.div style={styles} className="fadeDiv">
                    <button
                      className="main-referral-create-goal"
                      onClick={() => {
                        history.push("/dashboard/goal");
                      }}
                    >
                      Create New Goal
                    </button>
                    <Dropdown className="profile-dropdown">
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <div className="roundedDiv">
                          {getInitials(userData?.name || "")}
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {options.map((option, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => {
                              if ("route" in option) history.push(option.route);
                              option.fn();
                            }}
                          >
                            {option.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </animated.div>
                )
            )}
          </div>
        </div>
        {!animationFinish && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "80px",
              margin: "50px 0px",
            }}
          >
            {!expanded && (
              <div
                ref={ref}
                style={{
                  position: "relative",
                  visibility: expanded ? "hidden" : "unset",
                  width: expanded ? "0" : "70%",
                  height: expanded ? "0" : "unset",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p className="main-referral-search-toptext">
                  Let us find you a job in{" "}
                  {referralPrompts.length &&
                    referralPrompts[selectedIndex]?.country &&
                    referralPrompts[selectedIndex]["country"]}{" "}
                  for{" "}
                  {referralPrompts.length &&
                    referralPrompts[selectedIndex]?.referral_job_title &&
                    referralPrompts[selectedIndex]["referral_job_title"]}{" "}
                  role
                </p>

                <Form
                  onSubmit={handleSearch}
                  style={{
                    width: "80%",
                  }}
                >
                  <InputGroup
                    className={`main-referral-search-input-group focused ${
                      isFocused ? "focused" : ""
                    }`}
                  >
                    <Form.Control
                      ref={textareaRef}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      onInput={handleInput}
                      as="textarea"
                      className="main-referral-search-bar"
                      placeholder="Search Lazyapply AI..."
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      // onKeyPress={handleKeyPress}
                      style={{
                        minHeight: "30px",
                        height: height,
                        overflow: "hidden",
                        resize: "none",
                      }}
                    />
                    {searchValue.length != 0 ? (
                      <>
                        <div
                          className="main-referral-search-input-group-submit"
                          onClick={handleSearch}
                        >
                          <i className="fas fa-arrow-up"></i>
                        </div>
                      </>
                    ) : (
                      <>
                        <OverlayTrigger placement="top" overlay={tooltip1}>
                          <div
                            disabled={true}
                            className="main-referral-search-input-group-submit"
                            onClick={handleSearch}
                          >
                            <i className="fas fa-arrow-up"></i>
                          </div>
                        </OverlayTrigger>
                      </>
                    )}
                  </InputGroup>
                </Form>
              </div>
            )}
          </div>
        )}

        {transitions(
          (styles, item) =>
            item && (
              <animated.div
                style={styles}
                className="main-referral-prompt-container"
              >
                <div className="main-referral-prompt-examples-top-text">
                  <p>Get started with an example below</p>
                </div>
                <div className="main-referral-prompt-examples">
                  {[
                    examplePrompts.map((prompt, index) => {
                      return (
                        <>
                          <OverlayTrigger placement="bottom" overlay={tooltip}>
                            <div
                              id={prompt.id}
                              className="main-referral-prompt-examples-innerdiv"
                              onClick={(e) => {
                                setExamplePromptIndex(index);
                                setSearchValue(prompt.text);
                                textareaRef.current.focus();
                                setTimeout(() => {
                                  handleInput();
                                  // setExpanded(true);
                                  // handleSearchFromExample(index);
                                }, 10);
                              }}
                            >
                              {prompt.text}
                            </div>
                          </OverlayTrigger>
                        </>
                      );
                    }),
                  ]}
                </div>
              </animated.div>
            )
        )}

        {expanded && (
          <animated.div
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, -32px)",
              width: width.to((w) => `${w}%`),
              top: top.to((t) => `${t}%`),
              zIndex: 2,
            }}
          >
            <p className="main-referral-search-toptext">
              Let us find you a job in united states for software role{" "}
            </p>
            <Form onSubmit={handleSearch}>
              <InputGroup
                className={`main-referral-search-input-group ${
                  isFocused ? "focused" : ""
                }`}
              >
                <Form.Control
                  ref={textareaRef}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  onInput={handleInput}
                  as="textarea"
                  className="main-referral-search-bar"
                  placeholder="Search Lazyapply AI..."
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  // onKeyPress={handleKeyPress}
                  style={{
                    minHeight: "30px",
                    height: height,
                    overflow: "hidden",
                    resize: "none",
                  }}
                />
                {searchValue.length != 0 ? (
                  <>
                    <div
                      className="main-referral-search-input-group-submit"
                      onClick={(e) => {
                        handleSearchTopbar(e);
                      }}
                      disabled={companies.length > 0 ? false : true}
                    >
                      <i className="fas fa-arrow-up"></i>
                    </div>
                  </>
                ) : (
                  <>
                    <OverlayTrigger placement="top" overlay={tooltip1}>
                      <div
                        disabled={companies.length > 0 ? false : true}
                        className="main-referral-search-input-group-submit"
                        onClick={(e) => {
                          handleSearchTopbar(e);
                        }}
                      >
                        <i className="fas fa-arrow-up"></i>
                      </div>
                    </OverlayTrigger>
                  </>
                )}
              </InputGroup>
            </Form>
          </animated.div>
        )}

        <animated.div
          style={{
            ...contentAnimation,
            position: "relative",
            width: "100%",
            background: "transparent",
            display: expanded ? "flex" : "none",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "50px",
          }}
        >
          {animationFinish && !loadingFinish && (
            <DynamicSteps
              setLoadingFinish={setLoadingFinish}
              companies={companies}
            />
          )}

          {loadingFinish && animationFinish && (
            <ReferralList
              setShow={setShowMainReferralModal}
              showLoader={false}
              companies={companies}
              setmessage={setmessage}
              setmodalshow={setmodalshow}
              settitle={settitle}
              performTask2={performTask2}
            />
          )}
        </animated.div>
      </div>
      <SendEmailSuccess
        s={showEmailSentSuccess}
        closeFn={closeEmailSuccessModal}
      />
      <ReferralModal s={showMainReferralModal} closeFn={closeFn} />
      <InfoModal
        type={infoModalType}
        showInfoModal={showInfoModal}
        customFnInfoModal={() => {
          setShowInfoModal(false);
        }}
      />
      {showMainBlock && (
        <ReferralAutomationBlock
          setStepSelection={setStepSelection}
          mainAutomationStep={mainAutomationStep}
          show={emailAutomationReferral}
          setShowInfoModal={setShowInfoModal}
          setInfoModalType={setInfoModalType}
          checkIfStopped={checkIfStopped}
          pauseGlobalReferralStopPlayButton={pauseGlobalReferralStopPlayButton}
        />
      )}
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <InitBlock
          s={showEmailPermissionModal}
          closeFn={closePermissionModal}
        />
      </GoogleOAuthProvider>
      <UploadResume
        setShowInfoModal={setShowInfoModal}
        setInfoModalType={setInfoModalType}
        showProp={showCreateResumeModal}
        hideShow={closeUploadResumeModal}
        performTask2={performTask2}
      />
    </>
  );
};

export default AnimatedSearchBar;
