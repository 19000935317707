/*global chrome*/
import React, { useState, useEffect, useCallback, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import Dashboard from "./dashboard/dashboard";
import ScrollToTop from "./ScrollToTop";
import { Switch, Route, Redirect } from "react-router-dom";
import NoMatch from "./error";
import { useStateStore } from "../store/index";
import Login from "./login";
import CustomAlert from "./modal";
import { Hotjar } from "../index";
import axios from "axios";

function Popup() {
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const scriptinjected = useRef(null);
  const {
    setExtensionPresent,
    scriptInjectedGlobal,
    yourExtensionId,
    setChromeVersion,
    setScriptInjectedGlobal,
    globalLogout,
    userDataInit,
    updateToken,
    userImageSrc,
    userAuthentication,
    isUserAuthenticated,
  } = useStateStore();
  const userToken = useStateStore((state) => state.token);

  useEffect(() => {
    let token = null;
    let user = null;
    const usermain = JSON.parse(window.localStorage.getItem("user") || "{}");
    const tokenmain = window.localStorage.getItem("token");
    if (usermain != undefined && Object.keys(usermain).length > 0) {
      user = usermain;
    }
    if (tokenmain != undefined) {
      token = tokenmain;
    }
    console.log(tokenmain);

    if (token) {
      updateToken(token);
      axios
        .get(
          "https://backend.lazyapply.com/referral-user-service/getUserDetails",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(async (response) => {
          const v2Id = response.data?.user?.v2Id || "";
          console.log("v2idd", v2Id);
          let obj = {
            firstName: response.data?.user?.name,
            email: response.data?.user?.email,
          };
          Hotjar.identify(response.data?.user?.email, {
            firstName: response.data?.user?.name,
            email: response.data?.user?.email,
          });
          console.log("hotjar identity");
          console.log("onload", response.data);

          if (window.$crisp)
            window.$crisp.push([
              "set",
              "user:email",
              response.data?.user?.email,
            ]);

          window?.fcWidget?.user?.setProperties(obj);
          userDataInit(response.data?.user || {});
          userAuthentication(1);
        })
        .catch((error) => {
          console.log(error);
          if (error.message == "Request failed with status code 403") {
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
            }, 1500);
          } else {
            setmessage("Some error occured, please try again in some time!");
            settitle("Error");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2500);
          }
        });
    }
    if (user) {
      userImageSrc(user.picture);
    }
  }, [userToken]);

  useEffect(() => {
    if (typeof chrome !== "undefined" && chrome && chrome.runtime) {
      chrome.runtime.sendMessage(
        yourExtensionId,
        {
          lazyapply: "lazyapply",
          message: "check",
          injected: scriptInjectedGlobal,
        },
        function (response) {
          var lastError = chrome.runtime.lastError;
          if (lastError) {
            console.log(lastError.message, "error");
            return;
          }
          console.log("check", response);
          if ("status" in response && response.status == "installed") {
            setExtensionPresent(true);
            setScriptInjectedGlobal(true);
          }
        }
      );
    }
  }, []);

  const messageFn = useCallback((event) => {
    if (
      typeof event.data === "object" &&
      !Array.isArray(event.data) &&
      event.data !== null &&
      !scriptinjected.current &&
      event.data.injectedScript == "true"
    ) {
      if (event.data.version != -1) {
        setChromeVersion(event.data.version);
      }
      console.log("hereyoyo");
      setScriptInjectedGlobal(true);
      scriptinjected.current = true;
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", messageFn);
    return () => {
      window.removeEventListener("message", messageFn);
    };
  }, []);

  return (
    <>
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      <Switch>
        <Route exact path={`/`}>
          {isUserAuthenticated == 1 ? (
            <>
              <Redirect to="/dashboard" />
            </>
          ) : (
            <>
              <ScrollToTop>
                <>
                  <Login></Login>
                </>
              </ScrollToTop>
            </>
          )}
        </Route>
        <Route path="/dashboard">
          {isUserAuthenticated == 1 ? (
            <>
              <Dashboard></Dashboard>
            </>
          ) : (
            <>
              <Login></Login>
            </>
          )}
        </Route>
        <Route>
          <NoMatch />
        </Route>
      </Switch>
    </>
  );
}

export default Popup;
