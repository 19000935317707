import React, { useEffect, useRef, useState } from "react";
import { FormControl, Spinner } from "react-bootstrap";
import "./GoalComponent.css";
import { useHistory } from "react-router-dom";
import { useStateStore } from "../../store";
import { v1 as uuidv1 } from "uuid";
import axios from "axios";

const GoalComponent = () => {
  const inputRefs = [useRef(null), useRef(null), useRef(null)];
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showBack, setShowBack] = useState(false);
  const {
    referralPrompts,
    token,
    userData,
    setReferralPrompts,
    setCurrentGoalId,
  } = useStateStore();

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    console.log("referralPrompts", referralPrompts);
    if (referralPrompts.length > 0) {
      setShowBack(true);
    } else {
      setShowBack(false);
    }
  }, [referralPrompts]);

  const [errors, setErrors] = useState([0, 0, 0]);

  const modifyItemAtIndex = (index, newValue) => {
    setErrors((prevItems) =>
      prevItems.map((item, i) => (i === index ? newValue : item))
    );
  };

  const getStarted = async () => {
    const title = inputRefs[0].current.value;
    const salary = inputRefs[1].current.value;
    const country = inputRefs[2].current.value;
    if (title == "") {
      modifyItemAtIndex(0, 1);
    }
    if (salary == "") {
      modifyItemAtIndex(1, 1);
    }
    if (country == "") {
      modifyItemAtIndex(2, 1);
    }
    if (title != "" && salary != "" && country != "") {
      setLoading(true);
      let idnew = uuidv1();
      let goal_id = uuidv1();
      await axios.post(
        `https://backend.lazyapply.com/referral/prompts`,
        {
          v2Id: userData?.v2Id || "",
          prompt_text: "",
          prompt_second: "",
          goal_text: `I want a ${title} role with a salary more than ${salary} in ${country}`,
          id: idnew,
          goal_id: goal_id,
          main: true,
          key_values: {
            referral_job_title: title.trim(),
            employeeQuery: "",
            salary: salary,
            country: country,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setCurrentGoalId(goal_id);
      setTimeout(() => {
        history.push("/dashboard");
      }, 200);
    } else {
      setLoading(false);
    }
  };

  return (
    <div>
      {showBack && (
        <button className="goal-back-button" onClick={goBack}>
          back
        </button>
      )}
      <div className="goal-container-main">
        <div className="goal-container">
          <h2 className="goal-heading">Tell us about your goal</h2>
          <div className="goal-form">
            <div className="goal-description">
              <div> I want a </div>

              <div
                className={`dash-input ${errors[0] ? " dash-input-error" : ""}`}
              >
                <FormControl
                  ref={inputRefs[0]}
                  placeholder="Job Title ex. - Software Developer"
                  onChange={(e) => {
                    if (e.target.value != "") {
                      modifyItemAtIndex(0, 0);
                    }
                  }}
                />
              </div>
            </div>
            <div className="goal-description">
              <div>role with salary more than </div>
              <div
                className={`dash-input ${errors[1] ? " dash-input-error" : ""}`}
              >
                <FormControl
                  ref={inputRefs[1]}
                  placeholder="Salary ex. - 50k $"
                  onChange={(e) => {
                    if (e.target.value != "") {
                      modifyItemAtIndex(1, 0);
                    }
                  }}
                />
              </div>
            </div>
            <div className="goal-description">
              <div> in </div>
              <div
                className={`dash-input ${errors[2] ? " dash-input-error" : ""}`}
              >
                <FormControl
                  ref={inputRefs[2]}
                  placeholder="Country ex. - United States"
                  onChange={(e) => {
                    if (e.target.value != "") {
                      modifyItemAtIndex(2, 0);
                    }
                  }}
                />
              </div>
            </div>
            <div className="goal-description">
              <button disabled={loading} onClick={getStarted}>
                Get Started
                {loading && (
                  <Spinner
                    animation="border"
                    size="sm"
                    style={{ marginLeft: "5px" }}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalComponent;
