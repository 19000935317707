import React from "react";
import { FileUploader } from "react-drag-drop-files";

const FileUpload = ({ file, setFile }) => {
  const submitHandler = async (file) => {
    console.log("file", file);
    setFile(file)
  };

  const fileTypes = ["PDF", "DOCX"];
  return (
    <>
      <FileUploader
        disabled={file ? true : false}
        multiple={false}
        handleChange={submitHandler}
        name="file"
        fileOrFiles={file}
        types={fileTypes}
      />
    </>
  );
};

export default FileUpload;
