import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useStateStore } from "../../store";
import ReferralMainBlock from "./ReferralMainBlock";
import { useHistory } from "react-router-dom";

function ReferralList({
  setShow,
  companies,
  showLoader,
  setmessage,
  setmodalshow,
  settitle,
  performTask2
}) {
  const history = useHistory();
  const {
    selectedCompanies,
    clearAll,
    selectAll,
    setShowMainBlock,
    setGlobalStopReferral,
    setBackClick
  } = useStateStore();

  const [filter, setFilter] = useState("all");

  const handleSelectAll = () => {
    const allIndexes = companies.map((_, index) => index);
    selectAll(allIndexes);
  };

  const allSelected = selectedCompanies.length === companies.length;

  const handleSelectAllToggle = () => {
    if (allSelected) {
      clearAll();
    } else {
      const allIndexes = companies.map((_, index) => index);
      selectAll(allIndexes);
    }
  };

  const filteredCompanies = companies.filter((_, index) => {
    if (filter === "selected") {
      return selectedCompanies.includes(index);
    } else if (filter === "unselected") {
      return !selectedCompanies.includes(index);
    }
    return true;
  });

  const selectAllByDefault = () => {
    const allIndexes = companies.map((_, index) => index);
    selectAll(allIndexes);
  };

  useEffect(() => {
    selectAllByDefault();
  }, [companies]);

  return (
    <>
      {showLoader ? (
        <>
          {[1, 2, 3].map((index) => {
            return (
              <>
                <div className="main-referral-container skeleton-post main-referral-skeleton-post">
                  <div className="main-referral-top">
                    <div className="main-referral-top-first">
                      <div className="skeleton-logo"></div>
                    </div>
                    <div className="main-referral-top-second">
                      <div className="main-referral-company-name">
                        <div className="skeleton-line"></div>
                      </div>
                      <div className="main-referral-company-domain">
                        <div className="skeleton-line"></div>
                      </div>
                      <div className="main-referral-company-otherdetails">
                        <div className="skeleton-line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="main-referral-bottom">
                    <div>
                      <button className="skeleton-button"></button>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </>
      ) : (
        <>
          <div className="main-referral-companies-container">
            <div className="mb-3 main-referral-select-all-main-div">
              <div>
                <button
                  // variant="primary"
                  className="main-referral-select-all"
                  onClick={handleSelectAllToggle}
                >
                  {allSelected ? "Deselect All" : "Select All"}
                </button>
              </div>

              <div className="main-referral-select-all-second-div">
                <button
                  className="main-referral-select-all-start-automation"
                  onClick={() => {
                    // setmessage("You don't have a plan, purchase a plan to continue!");
                    // settitle("Purchase Plan!");
                    // setmodalshow(true);
                    setBackClick(0)
                    performTask2()
                    // setTimeout(() => {
                    //   setmodalshow(false);
                    //   history.push("/dashboard/pricing");
                    // }, 2000);
                    // setShowMainBlock(true);
                    // setGlobalStopReferral(false);
                  }}
                >
                  Start Automation
                </button>
              </div>
            </div>

            <div className="row">
              {filteredCompanies.map((company, index) => {
                return (
                  <ReferralMainBlock
                    company={company}
                    setShow={setShow}
                    mixpanel_page="secret_network_page"
                    index={index}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ReferralList;
