import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import "./pricing.css";
import { useHistory } from "react-router-dom";
import { useStateStore } from "../../store";
import PayPalButton from "../PaypalButton";

const PricingPlans = () => {
  const history = useHistory();
  const { setBackClick, token } = useStateStore();
  const goBack = () => {
    setBackClick(1);
    history.goBack();
  };


  return (
    <div className="referral-pricing-container-main">
      <button className="goal-back-button" onClick={goBack}>
        back
      </button>

      <div className="referral-pricing-container">
        <div className="text-center referral-pricing-main-heading-container">
          <h1 className="heading">Ready to Get Started?</h1>
          <p className="subheading mb-4">
            Choose a plan tailored to your needs
          </p>
        </div>
        <Row className="justify-content-center">
          {/* Basic Plan */}
          <Col
            xs={12}
            md={6}
            lg={4}
            className="d-flex align-items-stretch mb-5"
          >
            <Card className="pricing-card">
              <div className="banner enhanced-banner">
                Good for <span className="banner-highlight">1 +</span>{" "}
                interviews
              </div>
              <Card.Body className="pricing-card-body">
                <Card.Title>Lite Plan</Card.Title>
                <Card.Text>
                  A great starting option for users looking to explore.
                </Card.Text>
                <ul className="features-list">
                  <li>
                    <i className="fas fa-check"></i> 50 referrals
                  </li>
                  <li>
                    <i className="fas fa-check"></i> Live AI based company
                    search
                  </li>
                  <li>
                    <i className="fas fa-check"></i> Basic support
                  </li>
                </ul>
                <h3>$79</h3>
                <PayPalButton
                  titlemain={"lite"}
                  amount={79}
                  titleSecondary={""}
                />
              </Card.Body>
            </Card>
          </Col>

          {/* Preferred Plan */}
          <Col
            xs={12}
            md={6}
            lg={4}
            className="d-flex align-items-stretch mb-5"
          >
            <Card className="pricing-card preferred-card">
              <div className="banner enhanced-banner">
                Good for <span className="banner-highlight">5 +</span>{" "}
                interviews
              </div>
              <Card.Body className="pricing-card-body">
                <Card.Title>Pro Plan</Card.Title>
                <Card.Text>
                  The best value for most users, packed with great features.
                </Card.Text>
                <ul className="features-list">
                  <li>
                    <i className="fas fa-check"></i> 200 referrals
                  </li>
                  <li>
                    <i className="fas fa-check"></i> Live AI based company
                    search
                  </li>
                  <li>
                    <i className="fas fa-check"></i> Priority support
                  </li>
                </ul>
                <h3>$99</h3>
                <PayPalButton
                  titlemain={"pro"}
                  amount={99}
                  titleSecondary={""}
                />
              </Card.Body>
            </Card>
          </Col>

          {/* Premium Plan */}
          <Col
            xs={12}
            md={6}
            lg={4}
            className="d-flex align-items-stretch mb-5"
          >
            <Card className="pricing-card">
              <div className="banner enhanced-banner">
                Good for <span className="banner-highlight">20 +</span>{" "}
                interviews
              </div>
              <Card.Body className="pricing-card-body">
                <Card.Title>Ultimate Plan</Card.Title>
                <Card.Text>Advanced features and Referral Analytics</Card.Text>
                <ul className="features-list">
                  <li>
                    <i className="fas fa-check"></i> 1000 referrals
                  </li>
                  <li>
                    <i className="fas fa-check"></i> Live AI based company
                    search
                  </li>
                  <li>
                    <i className="fas fa-check"></i> Priority support
                  </li>
                </ul>
                <h3>$299</h3>
                <PayPalButton
                  titlemain={"ultimate"}
                  amount={299}
                  titleSecondary={""}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PricingPlans;
